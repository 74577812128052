import styled from "styled-components";

const ButtonLikeAnAnchor = styled.button`
  width: 100%;
  background: none;
  outline: none;
  border: none;
  text-align: left;
  cursor: pointer;
  color: #0088cc;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

export default ButtonLikeAnAnchor;
