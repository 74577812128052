import React, { useCallback, useState } from "react";
import { isImage } from "tools/content";
import {
  Dimensions,
  FileDescriptor,
  MediaTransformationsSpec,
  RotationAngle
} from "../UnifiedFilePicker/types";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import {
  Button,
  Col,
  Row,
  Select,
  Slider,
  Typography,
  Alert,
  Tooltip
} from "antd";
import {
  ArrowsAltOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ShrinkOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from "@ant-design/icons";
import Modal from "styleguide/Modal";

import { FormattedMessage } from "react-intl";
import messages from "./intl";
import { computeActualBox, computeZoomToFit } from "./utils";
import { useMobile } from "styleguide/mobile";

interface Props {
  visible: boolean;
  file: FileDescriptor;
  targetDimensions?: Dimensions;
  onCrop: (config: MediaTransformationsSpec) => void;
  onCancel: () => void;
}

const sizes = [
  { name: "1080p Landscape", value: [1920, 1080] },
  { name: "1080p Portrait", value: [1080, 1920] }
];

const ResizerCropper: React.FC<Props> = ({
  file,
  visible,
  onCrop,
  onCancel,
  targetDimensions
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState<RotationAngle>(0);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [selectedSize, setSelectedSize] = useState<Dimensions>([1920, 1080]);

  const isMobile = useMobile();

  const targetSize = targetDimensions || selectedSize;
  const zoomToFit = computeZoomToFit(targetSize, file.dimensions, rotation);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      const actualBox = computeActualBox(
        file.dimensions,
        rotation,
        croppedAreaPixels
      );
      setCroppedArea(actualBox);
    },
    [file.dimensions, rotation]
  );
  const rotateLeft = useCallback(() => {
    setRotation((rotation) => ((rotation + 270) % 360) as RotationAngle);
  }, []);
  const rotateRight = useCallback(() => {
    setRotation((rotation) => ((rotation + 90) % 360) as RotationAngle);
  }, []);
  const zoomOut = useCallback(() => {
    setZoom((zoom) => zoom - 0.1);
  }, []);
  const zoomIn = useCallback(() => {
    setZoom((zoom) => zoom + 0.1);
  }, []);
  const scaleToFit = useCallback(() => {
    setZoom(zoomToFit);
    setCrop({ x: 0, y: 0 });
  }, [zoomToFit]);

  const scaleToFill = useCallback(() => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  }, []);

  if (!isImage(file.name, file.mediaType)) {
    return null;
  }
  if (!file.dimensions) {
    return null;
  }

  if (!file.previewUrl) {
    return null;
  }

  const hasWarning =
    croppedArea &&
    (croppedArea.width < targetSize[0] || croppedArea.height < targetSize[1]);

  const allowEmptyPixels = !targetDimensions;
  const minZoom = allowEmptyPixels ? 0.1 : 1;

  return (
    <Modal
      style={{ top: "25px" }}
      open={visible}
      title={
        <div
          style={
            isMobile
              ? undefined
              : {
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline"
                }
          }
        >
          <Typography.Title level={4} style={{ flexGrow: 1 }}>
            <FormattedMessage {...messages.title} />
          </Typography.Title>
          <Typography.Text>
            <FormattedMessage {...messages.targetDimensions} />
          </Typography.Text>
          {targetDimensions ? (
            <Typography.Text
              style={{
                marginLeft: "16px",
                marginRight: "24px"
              }}
            >
              {" "}
              {targetDimensions[0]} x {targetDimensions[1]} px
            </Typography.Text>
          ) : (
            <Select
              value={selectedSize.join("x")}
              style={{
                width: "200px",
                marginRight: "24px",
                marginLeft: "16px"
              }}
              onChange={(value) =>
                setSelectedSize(
                  value.split("x").map((dim) => parseInt(dim)) as Dimensions
                )
              }
              optionLabelProp="label"
            >
              {sizes.map(({ name, value }) => (
                <Select.Option
                  value={value.join("x")}
                  key={value.join("x")}
                  label={name}
                >
                  {name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      }
      width={800}
      onCancel={onCancel}
      footer={null}
    >
      <Row
        style={{ height: isMobile ? "300px" : "400px", marginBottom: "16px" }}
      >
        <Col span={24}>
          <Cropper
            image={file.previewUrl}
            crop={crop}
            zoom={zoom}
            // if there is a backend validation of picture ratio, we cannot allow the user include empty pixels
            restrictPosition={!allowEmptyPixels}
            rotation={rotation}
            aspect={targetSize[0] / targetSize[1]}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: "16px" }}>
        <Col
          md={12}
          xs={24}
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"
          }}
        >
          <Tooltip title={<FormattedMessage {...messages.zoomOut} />}>
            <Button
              shape="circle"
              icon={<ZoomOutOutlined />}
              disabled={zoom <= minZoom}
              onClick={zoomOut}
              style={{ marginRight: "16px" }}
            />
          </Tooltip>
          <Slider
            value={zoom}
            marks={{ 0.1: "x0.1", 1: "x1", 3: "x3" }}
            style={{ flexGrow: 1 }}
            step={0.01}
            min={minZoom}
            max={3}
            onChange={(val) => setZoom(val as number)}
          />
          <Tooltip title={<FormattedMessage {...messages.zoomIn} />}>
            <Button
              shape="circle"
              icon={<ZoomInOutlined />}
              disabled={zoom >= 3}
              style={{ marginLeft: "16px" }}
              onClick={zoomIn}
            />
          </Tooltip>
        </Col>
        <Col
          md={12}
          xs={24}
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"
          }}
        >
          {allowEmptyPixels && (
            <Tooltip title={<FormattedMessage {...messages.fitArea} />}>
              <Button
                shape="circle"
                icon={<ShrinkOutlined />}
                disabled={
                  Math.abs(zoom - zoomToFit) <= 0.01 &&
                  crop.x === 0 &&
                  crop.y === 0
                }
                onClick={scaleToFit}
              ></Button>
            </Tooltip>
          )}
          <Tooltip title={<FormattedMessage {...messages.fillArea} />}>
            <Button
              shape="circle"
              icon={<ArrowsAltOutlined />}
              disabled={zoom === 1 && crop.x === 0 && crop.y === 0}
              onClick={scaleToFill}
            />
          </Tooltip>
          <Tooltip title={<FormattedMessage {...messages.rotateLeft} />}>
            <Button
              shape="circle"
              icon={<RotateLeftOutlined />}
              onClick={rotateLeft}
            />
          </Tooltip>
          <Tooltip title={<FormattedMessage {...messages.rotateRight} />}>
            <Button
              shape="circle"
              icon={<RotateRightOutlined />}
              onClick={rotateRight}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          {hasWarning && croppedArea && (
            <Alert
              message={
                <FormattedMessage
                  {...messages.notEnoughPixelsWarning}
                  values={{
                    currentWidth: croppedArea.width,
                    currentHeight: croppedArea.height,
                    targetWidth: targetSize[0],
                    targetHeight: targetSize[1]
                  }}
                />
              }
              style={{ marginBottom: "16px" }}
              type="warning"
              closable
            />
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onCancel}>
            <FormattedMessage id="generic.cancel" />
          </Button>
          {croppedArea && (
            <Button
              type="primary"
              style={{ marginLeft: "16px" }}
              onClick={() => onCrop({ ...croppedArea, rotation })}
            >
              <FormattedMessage {...messages.callToAction} />
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ResizerCropper;
