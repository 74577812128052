import * as React from "react";

function SvgEnvironmentFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M32 7c-10.43 0-20 7.14-20 20.64C12 39 26.36 52.86 30.71 56.5A2 2 0 0032 57a2 2 0 001.36-.5C37.71 52.86 52 39 52 27.64 52 14.14 42.5 7 32 7zm0 22.79a3 3 0 010-6 3 3 0 010 6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgEnvironmentFilled;
