import { isPDF, isImage, isVideo } from "../../../tools/content";
import { Dimensions, FileDescriptor, isRemoteFile } from "./types";
import videoRenderingFr from "./video_rendering_fr.mp4";
import videoRenderingEn from "./video_rendering_en.mp4";
import { IntlShape } from "react-intl";

interface FileAnalysis {
  previewUrl?: string;
  dimensions?: Dimensions;
}

const getDimensions = async (url: string) =>
  new Promise<Dimensions>((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve([img.width, img.height]);
    };
    img.src = url;
  });

const getPreviewUrl = async (
  file: FileDescriptor
): Promise<string | undefined> => {
  if (file.previewUrl) {
    return file.previewUrl;
  }
  if (isRemoteFile(file)) {
    return file.url;
  }
  const urlPromise = new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );

    reader.readAsDataURL(file.fileInstance as File);
  });

  return await urlPromise;
};

export const analyze = async (
  file: FileDescriptor,
  locale: string
): Promise<FileAnalysis> => {
  if (file.previewUrl && file.dimensions) {
    return {
      previewUrl: file.previewUrl,
      dimensions: file.dimensions
    };
  }
  if (isVideo(file.name, file.mediaType)) {
    if (isRemoteFile(file)) {
      return {
        previewUrl: await getPreviewUrl(file)
      };
    }
    return {
      previewUrl: locale === "fr" ? videoRenderingFr : videoRenderingEn
    };
  }

  const previewUrl = await getPreviewUrl(file);
  if (isPDF(file.name, file.mediaType)) return { previewUrl };
  if (isImage(file.name, file.mediaType) && previewUrl) {
    const dimensions = await getDimensions(previewUrl);
    return { previewUrl, dimensions };
  }

  return { previewUrl };
};

export class APIError extends Error {
  statusCode: number | null = null;
  body: any = null;
  codes?: string[] = [];

  constructor(statusCode: number, body: any, codes?: string[]) {
    super("API error");
    this.statusCode = statusCode;
    this.body = body;
    this.codes = codes;
  }
}

export class ContentError extends Error {
  statusCode: number | null = null;
  body: any = null;
  codes?: string[] = [];

  constructor(statusCode: number, body: any, codes?: string[]) {
    super("Content error");
    this.statusCode = statusCode;
    this.body = body;
    this.codes = codes;
  }
}

export abstract class TranslatableError extends Error {
  toString(intl: IntlShape): string {
    return super.toString();
  }
}

export class NetworkInterruptionError extends TranslatableError {
  toString(intl: IntlShape) {
    return intl.formatMessage({
      id: "generic.error.networkInterruption",
      defaultMessage:
        "Your network connection was interrupted during the data transfer. Please try again."
    });
  }
}

export const isAPIError = (err: Error): err is APIError => {
  return !!(err as APIError).codes;
};
