import React from "react";
import { Timeline, Progress } from "antd";

import * as Icon from "@ant-design/icons";

/**
 * Custom Timeline item
 *
 * @param {string|Component} step
 * @param {number} startPercent At what percentage does the step start
 * @param {endPercent} endPercent At what percentage does the step end
 * @param {boolean} progressBar Display a progress bar if true
 * @param {boolean} error
 */
export const TimelineItem = ({
  step,
  percent,
  startPercent,
  endPercent,
  progressBar,
  error
}) => {
  if (percent < startPercent) {
    return <TimelineItemPending step={step} />;
  } else if (percent > endPercent) {
    return <TimelineItemFinished step={step} />;
  } else {
    const relativePercent = Math.trunc(
      ((percent - startPercent) * 100) / (endPercent - startPercent)
    );
    return error ? (
      <TimelineItemError step={step} />
    ) : progressBar ? (
      <TimelineItemRunningProgressBar
        step={step}
        percentage={relativePercent}
      />
    ) : (
      <TimelineItemRunning step={step} />
    );
  }
};

export const TimelineItemPending = ({ step }) => (
  <Timeline.Item color="gray">{step}</Timeline.Item>
);

export const TimelineItemFinished = ({ step }) => (
  <Timeline.Item color="green">{step}</Timeline.Item>
);

export const TimelineItemRunning = ({ step }) => (
  <Timeline.Item dot={<Icon.LoadingOutlined style={{ fontSize: "24px" }} />}>
    {step}
  </Timeline.Item>
);

export const TimelineItemRunningProgressBar = ({ step, percentage }) => (
  <Timeline.Item>
    {step}
    <Progress
      percent={percentage}
      status={percentage === 100 ? "success" : "active"}
    />
  </Timeline.Item>
);

export const TimelineItemError = ({ step }) => (
  <Timeline.Item color="red">{step}</Timeline.Item>
);
