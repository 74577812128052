import * as React from "react";

function CropFilled(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="1em"
      height="1em"
      viewBox="0 0 489.8 489.8"
      {...props}
    >
      <polygon points="336.2,489.4 391.4,489.4 391.4,98 0,98 0,153.2 336.2,153.2" />
      <rect x="90.6" y="0.4" width="55.2" height="68" />
      <polygon points="306.7,352.2 138,352.2 138,183.1 82.8,183.1 82.8,407.4 306.7,407.4" />
      <rect x="421.4" y="344.4" width="68.4" height="55.2" />
    </svg>
  );
}

export default CropFilled;
