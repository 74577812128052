import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { pdfjs, Document, Page } from "react-pdf";

import messages from "./intl";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  url: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}
const PDFPreview: React.FC<Props> = ({ url, loading, setLoading }) => {
  const [pdfPreview, setPdfPreview] = useState<boolean>(false);

  if (!pdfPreview && !loading) {
    return <FormattedMessage {...messages.cantPreview} />;
  }

  return (
    <Document
      file={url}
      onLoadSuccess={() => {
        setLoading(false);
        setPdfPreview(true);
      }}
      onLoadError={() => {
        setLoading(false);
        setPdfPreview(false);
      }}
    >
      <Page pageNumber={1} height={180}></Page>
    </Document>
  );
};

export default PDFPreview;
