import * as React from "react";

function SvgRainFilled(props) {
  return (
    <svg
      id="RainFilled_svg__Icons"
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      {...props}
    >
      <defs />
      <path
        className="RainFilled_svg__cls-1"
        d="M29.14 50a3 3 0 006 0c0-1.65-3-5-3-5s-3 3.35-3 5zM17.14 50a3 3 0 006 0c0-1.65-3-5-3-5s-3 3.35-3 5zM41.14 50a3 3 0 006 0c0-1.65-3-5-3-5s-3 3.35-3 5zM49.79 21.45A11 11 0 0038.64 11a11.62 11.62 0 00-7.07 2.43 9.91 9.91 0 00-4.07-.86 10 10 0 00-9.71 7.23A10.9 10.9 0 009.5 30.25a11 11 0 0011.21 10.8h22.65a11 11 0 0011.14-10.8 10.69 10.69 0 00-4.71-8.8z"
      />
    </svg>
  );
}

export default SvgRainFilled;
