import * as React from "react";

function SvgBroadcastDetailsFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <g data-name="Broadcast details">
        <path d="M25.91 25.51L14.59 17c-.87-.67-1.59-.31-1.59.78v17.89c0 1.1.72 1.46 1.59.79l11.32-8.54a1.44 1.44 0 000-2.41zM54 19.75a1 1 0 01-1 1H24a1 1 0 01-1-1v-2a1 1 0 011-1h29a1 1 0 011 1zM54 27.71a1 1 0 01-1 1H31a1 1 0 01-1-1v-2a1 1 0 011-1h22a1 1 0 011 1zM54 35.67a1 1 0 01-1 1H24a1 1 0 01-1-1v-2a1 1 0 011-1h29a1 1 0 011 1zM54 43.64a1 1 0 01-1 1H13a1 1 0 01-1-1v-2a1 1 0 011-1h40a1 1 0 011 1z" />
      </g>
    </svg>
  );
}

export default SvgBroadcastDetailsFilled;
