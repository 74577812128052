import * as React from "react";
import { FormattedMessage } from "react-intl";

import SeeMoreLink from "../SeeMoreLink";
import { Popover } from "antd";

interface Props {
  totalCount: number;
  type: string;
  list: any[];
  seeMoreUrl?: string;
  allInPopover?: boolean;
  trigger?: React.ReactNode;
}

const PopoverList: React.FC<Props> = ({
  totalCount,
  seeMoreUrl,
  type,
  list,
  trigger,
  allInPopover = false
}) => {
  const triggerMessage = trigger ? (
    <>{trigger}</>
  ) : (
    <FormattedMessage id={type} values={{ total: totalCount }} />
  );
  if (totalCount === 0) {
    return triggerMessage;
  }

  const popoverContent = allInPopover ? (
    <>{list}</>
  ) : (
    <div style={{ maxWidth: "300px" }}>
      {list.slice(0, 9)}
      {totalCount > 9 &&
        (seeMoreUrl ? (
          <React.Fragment>
            <br />
            <SeeMoreLink data-testid="seeMoreLink" url={seeMoreUrl} />
          </React.Fragment>
        ) : (
          "..."
        ))}
    </div>
  );
  return (
    <Popover data-testid="popover" content={popoverContent}>
      <span>{triggerMessage}</span>
    </Popover>
  );
};

export default PopoverList;
