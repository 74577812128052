import * as React from "react";

function SvgCheckCircleOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M28.93 40.64a1.16 1.16 0 00.86.36 1.12 1.12 0 00.78-.36l12.57-12.35a1.14 1.14 0 00.36-.86.91.91 0 00-.36-.79L40 23.36a1 1 0 00-.86-.36 1.11 1.11 0 00-.85.36l-8.5 8.43-4.08-4a1.2 1.2 0 00-1.71 0l-3.14 3.28a1.14 1.14 0 00-.36.79 1.28 1.28 0 00.36.85zM32 53a21 21 0 1121-21 21 21 0 01-21 21zm0 4A25 25 0 107 32a25 25 0 0025 25z" />
    </svg>
  );
}

export default SvgCheckCircleOutlined;
