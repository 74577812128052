import * as React from "react";

function SvgPlusOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M26.5 57h11a2 2 0 002-2V39.5H55a2 2 0 002-2v-11a2 2 0 00-2-2H39.5V9a2 2 0 00-2-2h-11a2 2 0 00-2 2v15.5H9a2 2 0 00-2 2v11a2 2 0 002 2h15.5V55a2 2 0 002 2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlusOutlined;
