import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Card, Button } from "antd";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { ModalProps } from "antd/lib/modal";
import { FormattedMessage } from "react-intl";
import { useMobile } from "styleguide/mobile";

type Props = ModalProps;

const useMobileLockBodyScroll = (visible: boolean) => {
  const isMobile = useMobile();

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    if (!visible) {
      return;
    }
    const initialCount = parseInt(
      document.body.getAttribute("data-scroll-lock-count") || "0"
    );
    document.body.setAttribute(
      "data-scroll-lock-count",
      (initialCount + 1).toString()
    );
    document.body.classList.add("scroll-locked");

    return () => {
      const initialCount = parseInt(
        document.body.getAttribute("data-scroll-lock-count") || "0"
      );
      document.body.setAttribute(
        "data-scroll-lock-count",
        (initialCount - 1).toString()
      );
      if (initialCount - 1 <= 0) {
        document.body.classList.remove("scroll-locked");
      }
    };
  }, [isMobile, visible]);
};

/**
 * This modal uses the full viewport to give more space on mobile.
 */
const FullPageModal: React.FC<Props> = ({
  open,
  onCancel,
  title,
  children,
  footer,
  onOk,
  cancelText = <FormattedMessage id="generic.cancel" />,
  okText = <FormattedMessage id="generic.validate" />,
  closable = true,
  afterClose,
  bodyStyle
}) => {
  const doClose = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onCancel) {
        onCancel(e);
      }
      if (afterClose) {
        afterClose();
      }
    },
    [afterClose, onCancel]
  );

  useMobileLockBodyScroll(open || false);

  const [node, setNode] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = document.createElement("div");
    node.classList.add("full-page-modal", "hidden");
    document.body.appendChild(node);
    setNode(node);

    return () => {
      if (node) {
        node.remove();
        setNode(null);
      }
    };
  }, []);

  useEffect(() => {
    if (!node) {
      return;
    }
    if (open) {
      node.classList.remove("hidden");
    } else {
      node.classList.add("hidden");
    }
  }, [node, open]);

  if (!open) {
    return null;
  }
  if (!node) {
    return null;
  }

  return ReactDOM.createPortal(
    <Card title={title} bordered={false} bodyStyle={bodyStyle}>
      {closable && (
        <CloseOutlined
          onClick={doClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "16px",
            top: "20px"
          }}
        />
      )}
      {children}
      {footer !== null && footer !== false && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "16px"
          }}
        >
          {footer || (
            <>
              {closable && <Button onClick={doClose}>{cancelText}</Button>}
              <Button type="primary" onClick={onOk}>
                {okText}
              </Button>
            </>
          )}
        </div>
      )}
    </Card>,
    node
  );
};

export default FullPageModal;
