import React from "react";

import AntModal, { ModalProps } from "antd/lib/modal";
import { useMobile } from "styleguide/mobile";
import FullPageModal from "./FullPageModal";

type Props = ModalProps & {
  mobileBodyStyle?: React.CSSProperties;
};

const Modal: React.FC<Props> = (props) => {
  const isMobile = useMobile();

  if (!isMobile) {
    return <AntModal {...props} />;
  }

  return (
    <FullPageModal
      {...props}
      bodyStyle={props.mobileBodyStyle || props.bodyStyle}
    />
  );
};

export default Modal;
