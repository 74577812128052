import * as React from "react";

function SvgMultimediaOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M26.31 33.58h2.07a.5.5 0 00.51-.51v-4.22l1.71 4.44a.63.63 0 00.51.29h1.78a.63.63 0 00.51-.29l1.71-4.44v4.22a.5.5 0 00.51.51h2.07a.56.56 0 00.58-.51V22.69a.56.56 0 00-.58-.5h-2.86a.44.44 0 00-.43.36l-2.4 6.3-2.37-6.37a.58.58 0 00-.5-.29h-2.82a.56.56 0 00-.58.5v10.38a.56.56 0 00.58.51zM21 37.66V18.11h22v19.55zm0 8.23v-4.08h22v4.08zM19 50h25.92A2.08 2.08 0 0047 48V16a2.08 2.08 0 00-2.08-2H19a2 2 0 00-2 2v32a2 2 0 002 2z" />
    </svg>
  );
}

export default SvgMultimediaOutlined;
