import React, { useEffect, useRef } from "react";
import { Empty, Spin } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { APICallState, useCallJsonAPI } from "tools/api-requests";
import Loader from "styleguide/Loader";

interface InnerAsyncSectionProps<DataType extends object = any> {
  /**
   * A request bag returned from a hook based on useCallJsonAPI
   */
  state: APICallState<DataType>;
  /**
   * Whether to render a loader that fits within a text row. Defaults to false.
   */
  inline?: boolean;
  /**
   * Render prop: passes the non-null value of the data to the child
   */
  children: (value: DataType) => React.ReactNode;
}

type AsyncSectionProps<DataType extends object = any> = Omit<
  InnerAsyncSectionProps<DataType>,
  "state"
> & {
  /**
   * The URL to an endpoint returning JSON.
   */
  url: string;
};

function hasStateInProps<DataType extends object = any>(
  props: AsyncSectionProps<DataType> | InnerAsyncSectionProps<DataType>
): props is InnerAsyncSectionProps<DataType> {
  return !!(props as InnerAsyncSectionProps<DataType>).state;
}

const InnerAsyncSection = <DataType extends object = any>({
  state,
  children,
  inline = false
}: InnerAsyncSectionProps<DataType>) => {
  const { loading, error, value } = state;
  const hasBeenLoading = useRef<boolean>(false);

  const errorMessage = <FormattedMessage id="generic.error.oops" />;
  const errorDisplay = inline ? (
    errorMessage
  ) : (
    <Empty
      image={<WarningTwoTone style={{ fontSize: "96px" }} />}
      description={errorMessage}
    ></Empty>
  );

  useEffect(() => {
    if (loading && !hasBeenLoading.current) {
      hasBeenLoading.current = true;
    }
  }, [loading]);

  if (loading) {
    return inline ? <Spin size="small" /> : <Loader />;
  }

  if (error) {
    return errorDisplay;
  }

  if (!value) {
    if (!hasBeenLoading.current) {
      // We haven't loaded yet
      return inline ? <Spin size="small" /> : <Loader />;
    }
    // We have loaded and the value is still null, this is an error.
    return errorDisplay;
  }

  return <>{children(value)}</>;
};

const FullAsyncSection = <DataType extends object = any>({
  url,
  ...otherProps
}: AsyncSectionProps<DataType>) => {
  const [state, doFetch] = useCallJsonAPI<DataType>();

  useEffect(() => {
    doFetch(url);
  }, [doFetch, url]);

  return <InnerAsyncSection state={state} {...otherProps} />;
};

const AsyncSection = <DataType extends object = any>(
  props: InnerAsyncSectionProps<DataType> | AsyncSectionProps<DataType>
) => {
  if (hasStateInProps(props)) {
    return <InnerAsyncSection {...props} />;
  } else {
    return <FullAsyncSection {...props} />;
  }
};
export default AsyncSection;
