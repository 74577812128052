import * as React from "react";

function SvgQuestionCircleOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M29.5 36.14v-.93a4.46 4.46 0 01.86-2.92A9.52 9.52 0 0133 30.14a5.05 5.05 0 001.71-1.5 2.82 2.82 0 00.5-1.71 2.63 2.63 0 00-.78-1.93 3.19 3.19 0 00-2.22-.71 3.28 3.28 0 00-2.57 1.07 4.25 4.25 0 00-.93 2.93H24a8.88 8.88 0 011-4.08 7.08 7.08 0 012.79-2.85 8.48 8.48 0 014.35-1.07 10.84 10.84 0 014.22.78 6.42 6.42 0 012.71 2.22A5.48 5.48 0 0140 26.5a5.72 5.72 0 01-.86 3.36 9.6 9.6 0 01-2.57 2.43A9.26 9.26 0 0034.5 34a2.31 2.31 0 00-.64 1.71v.43zM32 53a21 21 0 1121-21 21 21 0 01-21 21zm-.14-9.29a2.39 2.39 0 01-1.93-.78 2.31 2.31 0 01-.86-1.86 2.65 2.65 0 01.79-1.93 2.86 2.86 0 012-.71 2.59 2.59 0 011.93.71 2.65 2.65 0 01.78 1.93 2.47 2.47 0 01-.78 1.86 2.4 2.4 0 01-1.93.78zM32 57A25 25 0 107 32a25 25 0 0025 25z" />
    </svg>
  );
}

export default SvgQuestionCircleOutlined;
