import { defineMessages } from "react-intl";

export default defineMessages({
  preview: {
    id: "filepicker.previewTitle",
    defaultMessage: "Media preview"
  },
  cantPreview: {
    id: "filepicker.cantPreview",
    defaultMessage: "This media cannot be previewed."
  },
  fileIsResized: {
    id: "filepicker.fileIsResized",
    defaultMessage: "This file is resized from its original version."
  }
});
