import { defineMessages } from "react-intl";

export default defineMessages({
  image: {
    id: "filepicker.external.image",
    defaultMessage: "{format} image"
  },
  video: {
    id: "filepicker.external.video",
    defaultMessage: "{format} video"
  },
  document: {
    id: "filepicker.external.document",
    defaultMessage: "{format} document"
  },
  directory: {
    id: "filepicker.external.directory",
    defaultMessage: "Directory"
  },
  unsupported: {
    id: "filepicker.external.unsupported",
    defaultMessage: "Unsupported file"
  },
  pickThisFile: {
    id: "filepicker.external.pickThisFile",
    defaultMessage: "Pick this file"
  },
  loadMore: {
    id: "filepicker.external.loadMore",
    defaultMessage: "Load more"
  },
  searchButton: {
    id: "filepicker.external.searchButton",
    defaultMessage: "Search..."
  },
  searchPlaceholder: {
    id: "filepicker.external.searchPlaceholder",
    defaultMessage: "Type your search here"
  },
  searchBreadcrumbItemLabel: {
    id: "filepicker.external.searchBreadcrumbItemLabel",
    defaultMessage: 'Search results for "{query}"'
  },
  excelDocument: {
    id: "filepicker.external.excel",
    defaultMessage: "Microsoft Excel document"
  },
  csvDocument: {
    id: "filepicker.external.csv",
    defaultMessage: "CSV document"
  },
  googleSpreadsheet: {
    id: "filepicker.external.gsheet",
    defaultMessage: "Google Spreadsheet document"
  }
});
