import React from "react";
import { FormattedMessage } from "react-intl";

const SeeMoreLink = ({ url }) => (
  <a href={url}>
    + <FormattedMessage id="seeMore" />
  </a>
);

export default SeeMoreLink;
