import { Row, Col, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useMobile } from "styleguide/mobile";
import ExternalFilePicker from "../ExternalFilePicker";
import FilePreview from "../FilePreview";
import LocalFilePicker from "../LocalFilePicker";
import ResizerCropper from "../ResizerCropper";
import FileList from "./FileList";
import messages from "./intl";
import {
  FileDescriptor,
  FileStatus,
  MediaTransformationsSpec,
  Props
} from "./types";

const containerHeight = "240px";
const colSizes = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12
};

const UnifiedFilePicker: React.FC<Props> = ({
  externalStorages,
  multiple = false,
  accept,
  files,
  targetDimensions,
  withCropper = false,
  displayLocalFilePicker = true,
  setFiles,
  doUpload,
  doUpdate,
  doDelete,
  editedFile: outerEditedFile,
  setEditedFile: setOuterEditedFile
}) => {
  const [previewedFile, setPreviewedFile] = useState<FileDescriptor | null>(
    files && files[0] ? files[0] : null
  );
  const [editedFile, _setEditedFile] = useState<FileDescriptor | null>(null);
  useEffect(() => {
    if (outerEditedFile) {
      _setEditedFile(outerEditedFile);
    }
  }, [outerEditedFile, _setEditedFile]);

  const setEditedFile = useCallback(
    (file: FileDescriptor | null) => {
      _setEditedFile(file);
      if (setOuterEditedFile) {
        setOuterEditedFile(file);
      }
    },
    [setOuterEditedFile]
  );

  const previewElementRef = useRef<HTMLDivElement | null>(null);

  const usableExternalStorages = externalStorages
    ? externalStorages.filter((storage) => !!storage.hasList)
    : [];
  const usesExternalStorage = !!usableExternalStorages.length;
  const chosenExternalStorage = usableExternalStorages[0];

  const isMobile = useMobile();

  const handleFileEvent = useCallback(
    (file: FileDescriptor) => {
      setFiles((files) => {
        if (!multiple) {
          return [file];
        }
        const newFileIndex = files.findIndex(
          (currentFile) => currentFile.localId === file.localId
        );
        if (newFileIndex === -1) {
          return [...files, file];
        }
        return Object.assign([...files], { [newFileIndex]: file });
      });
    },
    [multiple, setFiles]
  );

  const onUploadSuccess = useCallback(
    (file: FileDescriptor) => {
      handleFileEvent(file);
      setPreviewedFile(file);
      if (isMobile) {
        if (previewElementRef.current) {
          previewElementRef.current.scrollIntoView({
            behavior: "smooth"
          });
        }
      }
    },
    [handleFileEvent, isMobile]
  );

  const handleUpdate = useCallback(
    async (transformations: MediaTransformationsSpec) => {
      if (!editedFile) return;
      setEditedFile(null);

      if (doUpdate) {
        const preUpdateInstance: FileDescriptor = {
          ...editedFile,
          transformations,
          status: FileStatus.LOADING
        };
        handleFileEvent(preUpdateInstance);

        try {
          await doUpdate(preUpdateInstance);

          const postUpdateInstance: FileDescriptor = {
            ...preUpdateInstance,
            status: FileStatus.SUCCESS
          };

          handleFileEvent(postUpdateInstance);
          setPreviewedFile(postUpdateInstance);
        } catch (error) {
          const postErrorInstance: FileDescriptor = {
            ...preUpdateInstance,
            status: FileStatus.ERROR,
            error: error as Error
          };
          handleFileEvent(postErrorInstance);
        }
      } else {
        const instance: FileDescriptor = {
          ...editedFile,
          transformations
        };
        handleFileEvent(instance);
        setPreviewedFile(instance);
      }
    },
    [doUpdate, editedFile, handleFileEvent, setEditedFile]
  );

  const handleRemove = useCallback(
    async (fileToDelete: FileDescriptor) => {
      if (doDelete) {
        const preDeleteInstance: FileDescriptor = {
          ...fileToDelete,
          status: FileStatus.LOADING
        };
        handleFileEvent(preDeleteInstance);

        try {
          await doDelete(fileToDelete);

          setFiles(
            files.filter((file) => file.localId !== fileToDelete.localId)
          );
        } catch (error) {
          const postErrorInstance: FileDescriptor = {
            ...fileToDelete,
            status: FileStatus.ERROR,
            error: error as Error
          };
          handleFileEvent(postErrorInstance);
        }
      } else {
        setFiles((files) =>
          files.filter((file) => file.localId !== fileToDelete.localId)
        );
      }
      if (previewedFile?.localId === fileToDelete.localId) {
        setPreviewedFile(null);
      }
    },
    [doDelete, files, handleFileEvent, previewedFile, setFiles]
  );

  return (
    <Row gutter={24}>
      {withCropper && editedFile && (
        <ResizerCropper
          visible={!!editedFile}
          file={editedFile}
          targetDimensions={targetDimensions}
          onCancel={() => setEditedFile(null)}
          onCrop={handleUpdate}
        />
      )}
      <Col {...colSizes}>
        {usesExternalStorage && (
          <>
            <h4 style={{ marginBottom: "12px" }}>
              <FormattedMessage {...messages.chooseFromMediaLibrary} />
            </h4>
            <ExternalFilePicker
              accept={accept}
              multiple={multiple}
              externalStorage={chosenExternalStorage}
              doUpload={doUpload}
              doPreview={setPreviewedFile}
              onPreUpload={handleFileEvent}
              onUploadError={handleFileEvent}
              onUploadSuccess={onUploadSuccess}
              previewedFileId={previewedFile?.localId || undefined}
            />
            <br />
          </>
        )}
        {displayLocalFilePicker && (
          <LocalFilePicker
            accept={accept}
            multiple={multiple}
            doUpload={doUpload}
            onPreUpload={handleFileEvent}
            onUploadError={handleFileEvent}
            onUploadSuccess={onUploadSuccess}
          />
        )}
      </Col>
      <Col {...colSizes}>
        {previewedFile ? (
          <div
            style={{
              height: containerHeight,
              display: "flex",
              flexDirection: "column"
            }}
            ref={previewElementRef}
          >
            <h4>
              <FormattedMessage {...messages.preview} />
            </h4>
            <FilePreview file={previewedFile} key={previewedFile.previewUrl} />
          </div>
        ) : (
          !isMobile && (
            <div
              style={{
                height: containerHeight,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <h4>
                <FormattedMessage {...messages.preview} />
              </h4>
              <Typography.Text disabled>
                <FormattedMessage {...messages.pickFileToPreview} />
              </Typography.Text>
            </div>
          )
        )}

        <FileList
          files={files}
          previewedFile={previewedFile}
          withCropper={withCropper}
          targetDimensions={targetDimensions}
          handleRemove={handleRemove}
          setEditedFile={setEditedFile}
          setPreviewedFile={setPreviewedFile}
        />
      </Col>
    </Row>
  );
};

export default UnifiedFilePicker;
