import * as React from "react";

function SvgIntegrationFilled(props) {
  return (
    <svg
      id="IntegrationFilled_svg__Icons"
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      {...props}
    >
      <defs />
      <path
        className="IntegrationFilled_svg__cls-1"
        d="M34 15.68v-4.22a1 1 0 00-1-1h-2a1 1 0 00-1 1v4.22a19.22 19.22 0 012-.15 17.87 17.87 0 012 .15zM46.79 46.47a17.93 17.93 0 01-2.86 2.85l3 2.93a.89.89 0 00.71.29.78.78 0 00.65-.29l1.43-1.43a1 1 0 00.35-.71 1.16 1.16 0 00-.35-.72z"
      />
      <path
        className="IntegrationFilled_svg__cls-1"
        d="M35.14 42.82a.38.38 0 01-.64-.28L34.14 37a.29.29 0 01.36-.36L40 37a.38.38 0 01.29.64l-1.15 1.14 7.65 7.65A19 19 0 0034 15.68v12h1.64a.39.39 0 01.29.64l-3.64 4.14a.43.43 0 01-.58 0l-3.64-4.14a.4.4 0 01.36-.64H30v-12a19 19 0 00-12.79 30.79l7.65-7.65-1.15-1.14A.38.38 0 0124 37l5.5-.36a.36.36 0 01.43.36l-.43 5.5a.38.38 0 01-.64.28l-1.15-1.14-7.64 7.64a19 19 0 0023.86 0l-7.64-7.64zM17.21 46.47l-2.93 2.92a1.55 1.55 0 00-.28.72 1.27 1.27 0 00.28.71l1.43 1.43a.92.92 0 00.72.29 1 1 0 00.71-.29l2.93-2.93a17.93 17.93 0 01-2.86-2.85z"
      />
    </svg>
  );
}

export default SvgIntegrationFilled;
