import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { DoPreviewFn, RemoteFileDescriptor } from "../UnifiedFilePicker/types";

import messages from "./intl";

import { Button, List, Tooltip } from "antd";
import {
  extractExtension,
  isCSV,
  isExcel,
  isGoogleSheet,
  isImage,
  isPDF,
  isVideo
} from "tools/content";
import {
  CampaignImageFilled,
  CampaignPdfFilled,
  CampaignVideoOutlined,
  MultimediaOutlined
} from "../../Icon/cenareo";

interface ExternalFileProps {
  instance: RemoteFileDescriptor;
  onClick?: () => void;
  doPreview?: DoPreviewFn<RemoteFileDescriptor>;
  choosable: boolean;
  isPreviewed: boolean;
}

export const getMediaTypeText = (
  intl: IntlShape,
  filename: string,
  mediaType?: string
) => {
  const extension = extractExtension(filename) || "";
  if (isVideo(filename, mediaType))
    return intl.formatMessage(messages.video, {
      format: extension.toUpperCase()
    });
  if (isImage(filename, mediaType))
    return intl.formatMessage(messages.image, {
      format: extension.toUpperCase()
    });
  if (isPDF(filename, mediaType))
    return intl.formatMessage(messages.document, {
      format: extension.toUpperCase()
    });
  if (isExcel(filename, mediaType))
    return intl.formatMessage(messages.excelDocument);
  if (isCSV(filename, mediaType))
    return intl.formatMessage(messages.csvDocument);
  if (isGoogleSheet(filename, mediaType))
    return intl.formatMessage(messages.googleSpreadsheet);

  return mediaType;
};

const ExternalFile: React.FC<ExternalFileProps> = ({
  instance,
  onClick,
  doPreview,
  choosable,
  isPreviewed
}) => {
  const intl = useIntl();
  const mediaTypeText = choosable
    ? getMediaTypeText(intl, instance.name, instance.mediaType)
    : intl.formatMessage(messages.unsupported);

  const previewItem = useCallback(() => {
    if (doPreview) {
      doPreview(instance);
    }
  }, [doPreview, instance]);

  const previewable = choosable && !isPreviewed && doPreview;

  const actions =
    choosable && onClick
      ? [
          <Tooltip title={<FormattedMessage {...messages.pickThisFile} />}>
            <Button shape="circle" icon={<PlusOutlined />} onClick={onClick} />
          </Tooltip>
        ]
      : [];

  return (
    <List.Item
      actions={actions}
      style={{
        cursor: previewable ? "pointer" : undefined,
        background: isPreviewed ? "#e6f7ff" : undefined
      }}
      onClick={() => previewable && previewItem()}
    >
      <List.Item.Meta
        avatar={
          isVideo(instance.name, instance.mediaType) ? (
            <CampaignVideoOutlined style={{ fontSize: "2em" }} />
          ) : isImage(instance.name, instance.mediaType) ? (
            <CampaignImageFilled style={{ fontSize: "2em" }} />
          ) : isPDF(instance.name, instance.mediaType) ? (
            <CampaignPdfFilled style={{ fontSize: "2em" }} />
          ) : (
            <MultimediaOutlined style={{ fontSize: "2em" }} />
          )
        }
        title={instance.name}
        description={mediaTypeText}
      />
    </List.Item>
  );
};

export default ExternalFile;
