import * as React from "react";
import { normalizedSearchQuery, findTermInText } from "./isMatch";
import Highlighter from "react-highlight-words";

/**
 * Highlight search terms in a string
 *
 * @description it normalized a text and highlight it according to a research.
 *
 * @param {string} text The string to search in
 * @param {string} research The string to search for
 *
 * @returns a span with highlight
 */
export const HighlighterCustom = (text: string, research?: string | null) => {
  if (!research) return text;

  const researchNormalized = normalizedSearchQuery(research);

  if (text && research) {
    const allRealTerms: any[] = [];
    researchNormalized.forEach((term) => {
      const realTerm = findTermInText(text, term);
      if (realTerm) {
        allRealTerms.push(realTerm);
      }
    });

    const Tag = ({ children }: { children: string }) => (
      <span style={{ backgroundColor: "gold" }}>{children}</span>
    );

    if (allRealTerms) {
      return (
        <Highlighter
          searchWords={allRealTerms}
          autoEscape={true}
          textToHighlight={text}
          highlightTag={Tag}
        />
      );
    }
  }
  return text;
};

export { SearchInList } from "./SearchInList";
