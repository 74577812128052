export const registerUser = ({
  id,
  username,
  email,
  firstName,
  lastName,
  dateJoined,
  isStaff,
  company,
  demoUser,
  adFormType
}) => {
  window.mixpanel.identify(id);

  window.mixpanel.people.set({
    id,
    $username: username,
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
    $created: dateJoined,
    $staff: isStaff,
    $company: company,
    $demoUser: demoUser,
    $adFormType: adFormType
  });
};

export const trackEvent = ({ name, data }) => {
  window.mixpanel.track(name, data);
};

export const trackPageView = ({ name, element }) => {
  const d = new Date();
  const weekday = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ][d.getDay() - 1];

  trackEvent({
    name: "View page",
    data: {
      page: name,
      hour: d.getHours(),
      weekday: weekday,
      element: element,
      language: window.locale
    }
  });
};

export const trackExternalLink = ({ anchor, name, data }) => {
  try {
    window.mixpanel.track_links(anchor, name, data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("Failed to track link:", name);
  }
};
