import * as React from "react";

function SvgCampaignVideoOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M28.14 38a1.29 1.29 0 00.5-.14L36.5 33a1.25 1.25 0 000-2l-7.86-4.86a1.29 1.29 0 00-.5-.14A1.13 1.13 0 0027 27.14v9.72A1.13 1.13 0 0028.14 38zM14 44V20h36v24zm-.72 4h37.44A3.2 3.2 0 0054 44.86V19.14A3.2 3.2 0 0050.72 16H13.28A3.25 3.25 0 0010 19.14v25.72A3.25 3.25 0 0013.28 48z" />
    </svg>
  );
}

export default SvgCampaignVideoOutlined;
