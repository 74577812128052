import * as React from "react";

function SvgCampaignHtmlFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M50.72 16H13.28A3.25 3.25 0 0010 19.14v25.72A3.25 3.25 0 0013.28 48h37.44A3.2 3.2 0 0054 44.86V19.14A3.2 3.2 0 0050.72 16zM29.86 36.29a1 1 0 010 1.42l-.57.58a.94.94 0 01-.72.28 1 1 0 01-.71-.28l-5.57-5.58a1 1 0 010-1.42l5.57-5.58a1 1 0 01.71-.28.94.94 0 01.72.28l.57.58a1 1 0 010 1.42L25.57 32zm11.85-3.58l-5.57 5.58a1 1 0 01-.71.28.94.94 0 01-.72-.28l-.57-.58a1 1 0 010-1.42L38.43 32l-4.29-4.29a1 1 0 010-1.42l.57-.58a.94.94 0 01.72-.28 1 1 0 01.71.28l5.57 5.58a1 1 0 010 1.42z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCampaignHtmlFilled;
