import React from "react";
import { FormattedMessage } from "react-intl";
import { trackEvent } from "tools/mixpanel";

interface Props {
  page?: string;
  symptom?: string;
  content?: React.ReactNode;
}

const SupportFormLink: React.FC<Props> = ({ page, symptom, content }) => {
  const en =
    "/support/form/en?TICKET.thesymptom=" +
    symptom +
    "&TICKET.content=" +
    content;
  const supportLinks = {
    fr:
      "/support/form/fr?TICKET.thesymptom=" +
      symptom +
      "&TICKET.content=" +
      content,
    en: en,
    de: en,
    es: en,
    nl: en
  };

  return (
    <a
      href={supportLinks[window.locale || "en"] || supportLinks.en}
      onClick={() =>
        page &&
        trackEvent({
          name: "Request help",
          data: {
            page,
            "link name": "Support form"
          }
        })
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="generic.supportformlink" />
    </a>
  );
};

export default SupportFormLink;
