/**
 * isMatch is a method to test the match between name and research.
 *
 * @description it transforms the research with a regex to allow the special characters and uppercase/lowercase for the test.
 *
 * @params name is the string in the table - research is the search string
 *
 * @return a boolean - of the match between research and name
 */
export const isMatch = (text: string, research: string) => {
  const regex = new RegExp(
    research.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&"),
    "i"
  );
  return regex.test(text);
};

/**
 *  normalized is a method to normalize a text.
 *
 * @description it normalize a text in lowercase and move accent like this: è of Crème ends up expressed as e + ̀.
 * It using regex to remove the ` character.
 *
 * @params text is a string
 *
 * @return a string - of the text normalized
 */
export const normalized = (text: string) =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

/**
 * Find and return all the terms in the search expression.
 *
 * @description Use a regex to find in a string, words or group of words between quotes (i.e. terms)
 * that are separated by space.
 *
 * @param {String} text The search expression
 * @returns {Array} Array of terms
 */
export const normalizedSearchQuery = (text: string): Array<any> => {
  const findterms = text.match(/"([^"]+)"|(\S+)/g);
  if (findterms) {
    return findterms.map((element) => {
      return normalized(element.trim().replace(/"/g, ""));
    });
  } else {
    return [];
  }
};

/**
 * Find and return a pattern in a string
 *
 * @description Search a pattern in a string in a case and accent insensitive manner
 * but return the exact match (Case and Accent sensitive)
 *
 * @param {string} text The string to search in
 * @param {string} pattern the pattern to search for
 * @returns the exact pattern found
 */
export const findTermInText = (text: string, pattern: string) => {
  const textNormalized = normalized(text);
  pattern = normalized(pattern);

  if (pattern && isMatch(textNormalized, pattern)) {
    const pos = textNormalized.toLowerCase().indexOf(pattern.toLowerCase());
    return text.substring(pos, pos + pattern.length);
  } else {
    return;
  }
};
