import * as React from "react";

function SvgCalendarFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M48 15.86h-5.86V12.5a.53.53 0 00-.5-.5h-3a.49.49 0 00-.5.5v3.36h-12V12.5a.53.53 0 00-.5-.5h-3a.49.49 0 00-.5.5v3.36H16a2 2 0 00-2 2.07v32A2 2 0 0016 52h32a2.06 2.06 0 002-2.07v-32a2.05 2.05 0 00-2-2.07zm-9.86 4.64a.49.49 0 01.5-.5h3a.53.53 0 01.5.5v2a.53.53 0 01-.5.5h-3a.49.49 0 01-.5-.5zm-16 0a.49.49 0 01.5-.5h3a.53.53 0 01.5.5v2a.53.53 0 01-.5.5h-3a.49.49 0 01-.5-.5zM46 47.86H18V30.29h28z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCalendarFilled;
