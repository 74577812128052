import React, { createContext } from "react";

export const ThemeContext = createContext({ legacy: true });

export const ThemeProvider = ({ legacy = true, children }) => {
  return (
    <ThemeContext.Provider value={{ legacy: legacy }}>
      {children}
    </ThemeContext.Provider>
  );
};
