import { Button, Divider, Empty, List } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import messages from "./intl";
import FileDescriptorItem from "./item";

import { RightOutlined, WarningTwoTone } from "@ant-design/icons";
import { isSupported } from "tools/content";
import { ExternalStorage } from "tools/me/types";
import { GenericFileFilled } from "../../Icon/cenareo";
import {
  BaseFileDescriptor,
  FileStatus,
  FileWidgetProps,
  RemoteFileDescriptor
} from "../UnifiedFilePicker/types";
import { analyze } from "../UnifiedFilePicker/utils";
import BreadcrumbSearch, {
  getBreadcrumbItem,
  isSearchQuery,
  Location
} from "./BreadcrumbSearch";
import { APIFileOrDirectory, doSearch, fetchDirectoryContents } from "./client";

const DIRECTORY_MEDIA_TYPE = "application/directory";

type Props = FileWidgetProps<RemoteFileDescriptor> & {
  externalStorage: ExternalStorage;
  previewedFileId?: string;
};

export type RemoteDirectoryDescriptor = Omit<
  BaseFileDescriptor,
  "localId" | "backendId" | "error" | "status" | "mediaType"
> & { type: "directory"; url: string };

type FileOrDirectory = RemoteFileDescriptor | RemoteDirectoryDescriptor;

function isDirectory(
  instance: FileOrDirectory
): instance is RemoteDirectoryDescriptor {
  return instance.type === "directory";
}

// Helpers
const toInstance = (file: APIFileOrDirectory): FileOrDirectory => {
  if (file.mime_type === DIRECTORY_MEDIA_TYPE) {
    const directory: RemoteDirectoryDescriptor = {
      type: "directory",
      name: file.name,
      url: file.url
    };
    return directory;
  }
  const {
    mime_type: mediaType,
    preview_url: previewUrl,
    user_url: userUrl,
    ...restFile
  } = file;
  const remoteFile: RemoteFileDescriptor = {
    ...restFile,
    mediaType,
    previewUrl,
    userUrl,
    type: "remote",
    localId: uuidv4(),
    status: FileStatus.NEW
  };
  return remoteFile;
};

const ExternalFilePicker: React.FC<Props> = ({
  doUpload,
  doPreview,
  onPreUpload,
  onAnalyzed,
  onUploadError,
  onUploadSuccess,
  accept,
  previewedFileId,
  externalStorage
}) => {
  const intl = useIntl();
  const [breadcrumb, setBreadcrumb] = useState<Location[]>([]);
  const [treeData, setTreeData] = useState<FileOrDirectory[]>([]);
  const [nextPageId, setNextPageId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const currentLocation = breadcrumb.length
    ? breadcrumb[breadcrumb.length - 1]
    : null;

  const isAccepted = useCallback(
    (item: RemoteFileDescriptor) => {
      return isSupported(item.name, item.mediaType, accept);
    },
    [accept]
  );

  const errorDisplay = (
    <Empty
      image={<WarningTwoTone style={{ fontSize: "96px" }} />}
      style={{ margin: "16px 0" }}
      description={<FormattedMessage id="generic.error.oops" />}
    ></Empty>
  );

  const fetchData = useCallback(
    async (location: Location | null, pageId?: string | null) => {
      return isSearchQuery(location)
        ? await doSearch(externalStorage.name, accept, location.query)
        : await fetchDirectoryContents(
            externalStorage.name,
            accept,
            location?.url,
            pageId || undefined
          );
    },
    [accept, externalStorage.name]
  );

  useEffect(() => {
    const run = async (location: Location | null) => {
      setError(false);
      setLoading(true);
      try {
        const apiResponse = await fetchData(location);
        setTreeData(apiResponse.items.map(toInstance));
        setNextPageId(apiResponse.nextPageId || null);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    run(currentLocation);
  }, [currentLocation, fetchData]);

  useEffect(() => {
    setNextPageId(null);
    setLoadingMore(false);
  }, [currentLocation]);

  const fetchNextPage = useCallback(async () => {
    setLoadingMore(true);
    const apiResponse = await fetchData(currentLocation, nextPageId);
    setTreeData((currentTreeData) => [
      ...currentTreeData,
      ...apiResponse.items.map(toInstance)
    ]);
    setNextPageId(apiResponse.nextPageId || null);
    setLoadingMore(false);
  }, [currentLocation, fetchData, nextPageId]);

  const handleSelect = useCallback(
    async (item: RemoteFileDescriptor) => {
      const preUploadInstance: RemoteFileDescriptor = {
        ...item,
        status: !!doUpload ? FileStatus.LOADING : FileStatus.NEW
      };
      delete preUploadInstance.error;

      if (onPreUpload) {
        onPreUpload(preUploadInstance);
      }

      const { dimensions, previewUrl } = await analyze(
        preUploadInstance,
        intl.locale
      );
      const analyzedInstance = {
        ...preUploadInstance,
        dimensions,
        previewUrl,
        status: FileStatus.ANALYZED
      };
      if (onAnalyzed) {
        onAnalyzed(analyzedInstance);
      }

      if (doUpload) {
        try {
          const mediaId = await doUpload(analyzedInstance);

          const postUploadInstance: RemoteFileDescriptor = {
            ...analyzedInstance,
            backendId: mediaId,
            status: FileStatus.SUCCESS
          };

          if (onUploadSuccess) {
            onUploadSuccess(postUploadInstance);
          }
        } catch (error) {
          const postErrorInstance: RemoteFileDescriptor = {
            ...analyzedInstance,
            status: FileStatus.ERROR,
            error: error as Error
          };
          if (onUploadError) {
            onUploadError(postErrorInstance);
          }
        }
      } else {
        if (onUploadSuccess) {
          onUploadSuccess(analyzedInstance);
        }
      }
    },
    [
      doUpload,
      intl.locale,
      onAnalyzed,
      onPreUpload,
      onUploadError,
      onUploadSuccess
    ]
  );

  const previewFile = async (file: RemoteFileDescriptor) => {
    if (doPreview) {
      doPreview(file); // Makes the FilePreview show a spinner

      const { dimensions, previewUrl } = await analyze(file, intl.locale);
      const analyzedInstance = {
        ...file,
        dimensions,
        previewUrl
      };
      doPreview(analyzedInstance);
    }
  };

  const loadMore = () => {
    if (!nextPageId) {
      return null;
    }
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px"
        }}
      >
        <Button onClick={fetchNextPage} loading={loadingMore}>
          <FormattedMessage {...messages.loadMore} />
        </Button>
      </div>
    );
  };

  return (
    <>
      <div style={{ marginBottom: "12px" }}>
        <BreadcrumbSearch
          searchAvailable={externalStorage.hasSearch}
          breadcrumb={breadcrumb}
          setBreadcrumb={setBreadcrumb}
        />
      </div>
      <Divider style={{ margin: 0 }} />
      {error ? (
        errorDisplay
      ) : (
        <List
          loading={loading}
          loadMore={loadMore()}
          style={{ maxHeight: "26em", overflow: "auto" }}
          size="small"
          dataSource={treeData}
          renderItem={(item) => {
            if (isDirectory(item)) {
              return (
                <List.Item
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setBreadcrumb([...breadcrumb, getBreadcrumbItem(item)])
                  }
                  actions={[<RightOutlined style={{ fontSize: "1em" }} />]}
                >
                  <List.Item.Meta
                    avatar={<GenericFileFilled style={{ fontSize: "2em" }} />}
                    title={
                      <span
                        style={{
                          marginBottom: 0,
                          position: "relative",
                          top: "4px"
                        }}
                      >
                        {item.name}
                      </span>
                    }
                  />
                </List.Item>
              );
            }
            const choosable = isAccepted(item);

            return (
              <FileDescriptorItem
                key={item.localId}
                instance={item}
                choosable={choosable}
                isPreviewed={previewedFileId === item.localId}
                doPreview={previewFile}
                onClick={() => handleSelect(item)}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default ExternalFilePicker;
