import React, { useState, useEffect } from "react";
import { Upload } from "antd";

export const FileInput = ({ children, onChange, ...props }) => {
  const [fileList, setFileList] = useState([]);
  const beforeUpload = () => {
    return false;
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setFileList(fileList);
      return;
    }
    setFileList(props.initialFiles);
  }, [fileList, props.initialFiles]);

  const handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    onChange(info);
  };
  return (
    <Upload
      {...props}
      beforeUpload={beforeUpload}
      fileList={fileList}
      onChange={handleChange}
    >
      {children}
    </Upload>
  );
};
