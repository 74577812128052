import React, { createRef, useState } from "react";
import * as Icon from "@ant-design/icons";
import { Input } from "antd";

export const ClickableCopyableURLInput = ({ copyOnClick, ...props }) => {
  const ref = createRef();
  const [copied, setCopied] = useState(false);
  const openLink = () => {
    window.open(props.value, "_blank");
  };
  const copyValue = () => {
    if (ref.current && ref.current.input) {
      ref.current.input.select();
      ref.current.input.setSelectionRange(0, 99999);
      document.execCommand("copy");
      setCopied(true);
      ref.current.input.setSelectionRange(0, 0);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };
  return (
    <Input.Search
      ref={ref}
      {...props}
      readOnly
      className="clickable-copyable-input"
      onClick={copyOnClick ? copyValue : openLink}
      onSearch={copyValue}
      enterButton={copied ? <Icon.CheckOutlined /> : <Icon.CopyOutlined />}
    />
  );
};

export const InputWithSubLabel = ({ subLabel, ...props }) => (
  <React.Fragment>
    <span className="input-helptext">{subLabel}</span>
    <Input placeholder="input placeholder" {...props} />
  </React.Fragment>
);

export default Input;
