import { Area } from "react-easy-crop/types";
import {
  Dimensions,
  FileDescriptor,
  MediaTransformationsSpec
} from "../UnifiedFilePicker/types";
import { isVideo } from "../../../tools/content";

export const rotateDimensions = (
  dimensions: Dimensions,
  rotation: number
): Dimensions => {
  const [width, height] = dimensions;
  if (rotation % 180) {
    return [height, width];
  }
  return [width, height];
};

export const computeActualBox = (
  baseDimensions: Dimensions | undefined,
  rotation: number,
  croppedArea: Area
): Area => {
  if (!baseDimensions) return croppedArea;
  const rotatedDimensions = rotateDimensions(baseDimensions, rotation);

  const { width, height } = croppedArea; // those are good
  const { x: reportedX, y: reportedY } = croppedArea; // those are crazy

  const widthDifference = baseDimensions[0] - rotatedDimensions[0];
  const reportedXLeft = Math.floor(widthDifference / 2);
  const x = reportedX - reportedXLeft;

  const heightDifference = baseDimensions[1] - rotatedDimensions[1];
  const reportedYOnTop = Math.floor(heightDifference / 2);
  const y = reportedY - reportedYOnTop;

  return { x, y, width, height };
};

export const computeComponentBox = (
  baseDimensions: Dimensions | undefined,
  transformations: MediaTransformationsSpec | undefined
): Area | undefined => {
  if (!baseDimensions) return undefined;
  if (!transformations) return undefined;

  const { rotation, width, height, x: actualX, y: actualY } = transformations;
  const rotatedDimensions = rotateDimensions(baseDimensions, rotation);

  const widthDifference = rotatedDimensions[0] - baseDimensions[0];
  const reportedXLeft = Math.floor(widthDifference / 2);
  const x = actualX - reportedXLeft;

  const heightDifference = rotatedDimensions[1] - baseDimensions[1];
  const reportedYOnTop = Math.floor(heightDifference / 2);
  const y = actualY - reportedYOnTop;

  return { width, height, x, y };
};

export const hasTransformations = (file: FileDescriptor) => {
  if (!file.transformations) return false;
  if (!file.dimensions) return false;

  return (
    file.transformations &&
    (file.transformations.rotation ||
      file.transformations.x ||
      file.transformations.y ||
      file.transformations.width !== file.dimensions[0] ||
      file.transformations.height !== file.dimensions[1])
  );
};

export const computeZoomToFit = (
  targetRatio: Dimensions,
  dimensions: Dimensions | undefined,
  rotation: number
) => {
  if (!dimensions) return 1;
  const rotatedDimensions = rotateDimensions(dimensions, rotation);

  const xScale = rotatedDimensions[0] / targetRatio[0];
  const yScale = rotatedDimensions[1] / targetRatio[1];
  return Math.min(xScale / yScale, yScale / xScale);
};

export const needsCropping = (
  file: FileDescriptor,
  targetDimensions?: Dimensions
) => {
  if (isVideo(file.name)) return false;
  if (!file.dimensions) return false;
  if (!targetDimensions) return false;
  const finalDimensions: Dimensions = file.transformations
    ? [file.transformations.width, file.transformations.height]
    : file.dimensions;

  const currentRatio = finalDimensions[0] / finalDimensions[1];
  const targetRatio = targetDimensions[0] / targetDimensions[1];

  return Math.abs(currentRatio - targetRatio) > 0.05;
};
