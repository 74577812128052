import React from "react";
import { Empty } from "antd";
import spinner from "./spinner.gif";

const Loader: React.FC<{}> = () => (
  <Empty image={false} description={false}>
    <img src={spinner} alt="loading" />
  </Empty>
);

export default Loader;
