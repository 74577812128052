import { ExternalStorage } from "tools/me/types";
import React from "react";
import { Area } from "react-easy-crop/types";

export enum FileStatus {
  NEW = "new",
  LOADING = "loading",
  ANALYZED = "analyzed",
  SUCCESS = "success",
  ERROR = "error",
  STOP = "stop"
}

export type Dimensions = [number, number];

export type RotationAngle = 0 | 90 | 180 | 270;

export type MediaTransformationsSpec = Area & {
  rotation: RotationAngle;
};

export type BaseFileDescriptor = {
  type: "local" | "remote" | "directory";
  localId: string;
  backendId?: string;
  error?: Error;
  status: FileStatus;
  name: string;
  mediaType?: string;
  dimensions?: Dimensions;
  previewUrl?: string;
  size?: number;
  transformations?: MediaTransformationsSpec;
};

export type LocalFileDescriptor = BaseFileDescriptor & {
  fileInstance?: File;
  type: "local";
  uploadProgress?: number;
};

export type RemoteFileDescriptor = BaseFileDescriptor & {
  url: string;
  userUrl?: string;
  type: "remote";
};

export type FileDescriptor = LocalFileDescriptor | RemoteFileDescriptor;

export function isLocalFile(item: FileDescriptor): item is LocalFileDescriptor {
  return item.type === "local";
}

export function isRemoteFile(
  item: FileDescriptor
): item is RemoteFileDescriptor {
  return item.type === "remote";
}

export function isFileDescriptor(item: any): item is FileDescriptor {
  // A FileDescriptor is an object
  if (typeof item !== "object" || !(item instanceof Object)) return false;

  const keys = Object.keys(item);

  // It must have some keys
  const mandatoryKeys = ["type", "localId", "status", "name"];
  const missingMandatoryKeys = mandatoryKeys.filter(
    (key: string): boolean => keys.indexOf(key) === -1
  );
  if (missingMandatoryKeys.length > 0) return false;

  // It can have some keys
  const optionalKeys = [
    "backendId",
    "error",
    "mediaType",
    "dimensions",
    "previewUrl",
    "size",
    "transformations",
    "fileInstance",
    "url",
    "userUrl"
  ];
  return (
    keys.filter(
      (key: string): boolean =>
        optionalKeys.indexOf(key) === -1 && mandatoryKeys.indexOf(key) === -1
    ).length === 0
  );
}

export type DoUploadFn<instanceType = FileDescriptor> = (
  instance: instanceType
) => Promise<string>;

export type DoUpdateFn<instanceType = FileDescriptor> = (
  instance: instanceType
) => void;

export type DoPreviewFn<instanceType = FileDescriptor> = (
  instance: instanceType
) => void;

export type DoDeleteFn<instanceType = FileDescriptor> = (
  instance: instanceType
) => Promise<void>;

export interface Props {
  /**
   * The current files (use like a controlled input)
   */
  files: FileDescriptor[];
  /**
   * The setter for current files (use like a controlled input)
   */
  setFiles: React.Dispatch<React.SetStateAction<FileDescriptor[]>>;
  /**
   * The HTML `accept` attribute on file inputs: defines what media types to accept.
   */
  accept?: string;
  /**
   * The HTML `multiple` attribute on file inputs: defines whether we accept more than one file. Defaults to false.
   */
  multiple?: boolean;
  /**
   * The dimensions that the media should fill. If set, cropping will be required for non-fitting media.
   */
  targetDimensions?: Dimensions;
  /**
   * A list of available external file storages.
   */
  externalStorages?: ExternalStorage[];
  /**
   * Whether to allow editing (cropping, rotating, resizing) medias. Defaults to false.
   */
  withCropper?: boolean;
  /**
   * Whether to show the local file picker. Defaults to true.
   */
  displayLocalFilePicker?: boolean;
  /**
   * Defines how to upload the file upon selection. Leave empty in order to not upload the file.
   *
   * @param file the FileDescriptor instance
   *
   * @return a promise returning the backend ID of the saved file. This backend ID is added to the instance.
   */
  doUpload?: DoUploadFn;
  /**
   * Defines how to update the file in the backend, for instance to change the cropping.
   *
   * @param file the FileDescriptor instance
   *
   * @return a promise.
   */
  doUpdate?: DoUpdateFn;
  /**
   * Defines how to delete the file from the backend, if it was already uploaded. Leave empty in order to not delete the file.
   *
   * @param file the FileDescriptor instance
   *
   * @return a promise.
   */
  doDelete?: DoDeleteFn;
  /**
   * Outside control of the ResizerCropper state
   */
  editedFile?: FileDescriptor | null;
  setEditedFile?: (file: FileDescriptor | null) => void;
}

export interface FileWidgetProps<instanceType extends FileDescriptor> {
  accept?: string;

  multiple?: boolean;
  doUpload?: DoUploadFn<instanceType>;
  doPreview?: DoPreviewFn;
  onPreUpload?: (instance: instanceType) => void;
  onAnalyzed?: (instance: instanceType) => void;
  onUploadSuccess?: (instance: instanceType) => void;
  onUploadError?: (instance: instanceType) => void;
}
