import CustomIcon, { DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, List, Spin, Typography, Progress } from "antd";
import React from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { CropFilled } from "styleguide/Icon/cenareo";
import { isImage, isPDF, isVideo } from "tools/content";
import {
  CampaignImageFilled,
  CampaignPdfFilled,
  CampaignVideoOutlined,
  MultimediaOutlined
} from "../../Icon/cenareo";
import { getMediaTypeText } from "../ExternalFilePicker/item";
import { needsCropping } from "../ResizerCropper/utils";
import messages from "./intl";
import {
  Dimensions,
  FileDescriptor,
  FileStatus,
  Props as UFPProps
} from "./types";
import { isAPIError, TranslatableError } from "./utils";

type Props = Pick<UFPProps, "files" | "withCropper" | "targetDimensions"> & {
  handleRemove: (fileToDelete: FileDescriptor) => Promise<void>;
  previewedFile: FileDescriptor | null;
  setEditedFile: (file: FileDescriptor) => void;
  setPreviewedFile: (file: FileDescriptor) => void;
  withCropper: boolean;
};

function getDescription(
  intl: IntlShape,
  file: FileDescriptor
): React.ReactNode {
  if (!file.error) {
    return getMediaTypeText(intl, file.name, file.mediaType);
  }

  if (isAPIError(file.error)) {
    if (file.error.codes!.length > 0) {
      return file.error.codes!.map((code) => (
        <Typography.Text type="danger" key={code}>
          {`errors.${code}` in messages ? (
            // @ts-ignore checked above
            <FormattedMessage {...messages[`errors.${code}`]} />
          ) : (
            code
          )}
        </Typography.Text>
      ));
    } else {
      return (
        <Typography.Text type="danger">
          {file.error.body.detail}
        </Typography.Text>
      );
    }
  }

  if (file.error instanceof TranslatableError) {
    return (
      <Typography.Text type="danger">
        {file.error.toString(intl)}
      </Typography.Text>
    );
  }

  return (
    <Typography.Text type="danger">
      <FormattedMessage {...messages.error} />: {file.error.toString()}
    </Typography.Text>
  );
}

const getAvailableActions = (
  intl: IntlShape,
  file: FileDescriptor,
  handleRemove: (fileToDelete: FileDescriptor) => Promise<void>,
  withCropper: boolean,
  handleEdit: (file: FileDescriptor) => void,
  targetDimensions?: Dimensions
): React.ReactNode[] => {
  if (file.status === FileStatus.LOADING) {
    return [<Spin />];
  }

  const actions = [
    <Button
      shape="circle"
      title={intl.formatMessage(messages.deleteFile)}
      icon={<DeleteOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        handleRemove(file);
      }}
    />
  ];

  if (withCropper && isImage(file.name, file.mediaType)) {
    const style: React.CSSProperties = needsCropping(file, targetDimensions)
      ? { background: "#fff3cd", borderColor: "#ffecb5" }
      : {};

    actions.unshift(
      <Button
        style={style}
        shape="circle"
        title={intl.formatMessage(messages.resizeFile)}
        icon={<CustomIcon component={CropFilled} />}
        onClick={() => handleEdit(file)}
      />
    );
  }

  return actions;
};

const FileList: React.FC<Props> = ({
  files,
  previewedFile,
  handleRemove,
  withCropper,
  setEditedFile,
  setPreviewedFile,
  targetDimensions
}) => {
  const intl = useIntl();
  return (
    <>
      <div style={{ marginBottom: "12px" }}>
        <h4>
          <FormattedMessage id="filepicker.selectedFiles" />
        </h4>
      </div>
      <Divider style={{ margin: 0 }} />
      <List
        style={{ maxHeight: "27em", overflow: "auto" }}
        size="small"
        dataSource={files}
        locale={{
          emptyText: intl.formatMessage({ id: "filepicker.noFilesSelected" })
        }}
        renderItem={(file) => {
          const isPreviewed = file.localId === previewedFile?.localId;
          const previewable = !isPreviewed && !!file.previewUrl;
          const description = getDescription(intl, file);
          const actions = getAvailableActions(
            intl,
            file,
            handleRemove,
            withCropper,
            setEditedFile,
            targetDimensions
          );

          return (
            <>
              <List.Item
                key={file.localId}
                actions={actions}
                style={{
                  cursor: previewable ? "pointer" : undefined,
                  background: isPreviewed ? "#e6f7ff" : undefined
                }}
                onClick={() => !!file.previewUrl && setPreviewedFile(file)}
              >
                <List.Item.Meta
                  avatar={
                    isVideo(file.name, file.mediaType) ? (
                      <CampaignVideoOutlined style={{ fontSize: "2em" }} />
                    ) : isImage(file.name, file.mediaType) ? (
                      <CampaignImageFilled style={{ fontSize: "2em" }} />
                    ) : isPDF(file.name, file.mediaType) ? (
                      <CampaignPdfFilled style={{ fontSize: "2em" }} />
                    ) : (
                      <MultimediaOutlined style={{ fontSize: "2em" }} />
                    )
                  }
                  title={file.name}
                  description={description}
                />
              </List.Item>
              {file.type === "local" && file.status !== FileStatus.SUCCESS && (
                <div style={{ padding: "0 20px" }}>
                  <Progress
                    percent={file.uploadProgress!}
                    status={
                      file.status === FileStatus.LOADING
                        ? "active"
                        : file.status === FileStatus.ERROR
                        ? "exception"
                        : undefined
                    }
                  />
                </div>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default FileList;
