import CustomIcon from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CropFilled } from "styleguide/Icon/cenareo";
import { useMobile } from "styleguide/mobile";
import { isImage, isPDF, isVideo } from "tools/content";
import { rotateDimensions } from "../ResizerCropper/utils";
import { Dimensions, FileDescriptor } from "../UnifiedFilePicker/types";
import BasicFilePreview from "./BasicFilePreview";
import messages from "./intl";
import PDFPreview from "./PDFPreview";

type AnalyzedFileDescriptor = FileDescriptor & {
  dimensions: Dimensions;
};

export interface Props {
  file: FileDescriptor;
}

const maxHeight = 180;
const desktopMaxWidth = 500;
const mobileMaxWidth = 350;

const getPicturePreviewBox = (
  file: AnalyzedFileDescriptor,
  isMobile: boolean
) => {
  const maxWidth = isMobile ? mobileMaxWidth : desktopMaxWidth;
  const croppedDimensions: Dimensions = file.transformations
    ? [file.transformations.width, file.transformations.height]
    : file.dimensions;

  const rotatedBaseDimensions = rotateDimensions(
    file.dimensions,
    file.transformations?.rotation || 0
  );

  const wScaleRatio = maxWidth / croppedDimensions[0];
  const hScaleRatio = maxHeight / croppedDimensions[1];
  const scaleRatio = Math.min(hScaleRatio, wScaleRatio);
  const boxWidth = scaleRatio * croppedDimensions[0];
  const fullMediaWidth = scaleRatio * rotatedBaseDimensions[0];
  const fullMediaHeight = scaleRatio * rotatedBaseDimensions[1];
  const rotatedBox = rotateDimensions(
    [fullMediaWidth, fullMediaHeight],
    file.transformations?.rotation || 0
  );

  let x = -(file.transformations?.x || 0) * scaleRatio;
  let y = -(file.transformations?.y || 0) * scaleRatio;
  const rotation = file.transformations?.rotation || 0;
  if (rotation === 90 || rotation === 180) {
    x += fullMediaWidth;
  }
  if (rotation === 180 || rotation === 270) {
    y += fullMediaHeight;
  }
  return { rotatedBox, x, y, boxWidth, rotation };
};

const frameStyle: React.CSSProperties = {
  height: maxHeight + "px",
  position: "relative",
  overflow: "hidden",
  margin: "0 auto"
};

const FilePreview: React.FC<Props> = ({ file }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);

  // We need to manually trigger a load event for the video element whenever
  // the url changes
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMobile = useMobile();

  useEffect(() => {
    if (isVideo(file.name, file.mediaType) && !!file.previewUrl) {
      if (videoRef.current) {
        videoRef.current.load();
      }
    }
  }, [file]);

  if (!file.previewUrl) {
    return (
      <Spin
        style={{
          margin: "auto",
          display: "block"
        }}
      />
    );
  }

  if (isPDF(file.name, file.mediaType)) {
    return (
      <PDFPreview
        url={file.previewUrl}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  if (
    !isImage(file.name, file.mediaType) &&
    !isVideo(file.name, file.mediaType)
  ) {
    return <FormattedMessage {...messages.cantPreview} />;
  }

  if (!file.dimensions) {
    return <BasicFilePreview file={file} />;
  }

  const isTransformed = !!file.transformations;

  const { x, y, rotatedBox, boxWidth, rotation } = getPicturePreviewBox(
    file as AnalyzedFileDescriptor,
    isMobile
  );

  const hasLoaded = () => {
    setLoading(false);
  };

  const mediaStyle: React.CSSProperties = {
    height: rotatedBox[1],
    width: rotatedBox[0],
    position: "absolute",
    left: x,
    top: y,
    transform: `rotate(${rotation}deg)`,
    transformOrigin: "top left",
    display: loading ? "none" : "inherit"
  };

  return (
    <div style={{ ...frameStyle, width: boxWidth }}>
      {loading && (
        <Spin
          style={{
            margin: "auto",
            display: "block"
          }}
        />
      )}
      {isVideo(file.name, file.mediaType) ? (
        <video
          ref={videoRef}
          onCanPlay={hasLoaded}
          loop
          style={mediaStyle}
          muted
          autoPlay
        >
          <source src={file.previewUrl} />
        </video>
      ) : (
        <img
          alt={intl.formatMessage(messages.preview)}
          onLoad={hasLoaded}
          style={mediaStyle}
          src={file.previewUrl}
        />
      )}
      {isTransformed && (
        <Tooltip title={<FormattedMessage {...messages.fileIsResized} />}>
          <CustomIcon
            component={CropFilled}
            style={{
              position: "absolute",
              right: "8px",
              bottom: "8px",
              fontSize: "24px",
              color: "black"
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default FilePreview;
