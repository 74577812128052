import * as React from "react";

function SvgSearchOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M27.86 38.29A10.38 10.38 0 0117.5 27.86a10.43 10.43 0 013-7.36 10.44 10.44 0 0114.79 0 10.43 10.43 0 013 7.36 10.38 10.38 0 01-10.43 10.43zM46.72 54.5a1.57 1.57 0 001.21-.5L54 47.93a1.73 1.73 0 000-2.43l-9.57-9.57a18.87 18.87 0 001.86-8.07 18.52 18.52 0 00-5.43-13 18.4 18.4 0 00-31.36 13 18.09 18.09 0 005.43 13 18.29 18.29 0 0012.93 5.36 17.9 17.9 0 008.07-1.86L45.5 54a1.68 1.68 0 001.22.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSearchOutlined;
