import * as React from "react";

function SvgRemoteOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M26.64 14.57a.8.8 0 00.57-.21 7.36 7.36 0 014.5-1.58 7.12 7.12 0 014.43 1.58.84.84 0 00.57.21.92.92 0 00.72-.29l2-2.07a.78.78 0 00.28-.64.93.93 0 00-.35-.79 12 12 0 00-15.36 0 1.18 1.18 0 00-.36.79.68.68 0 00.29.64l2 2.07a.89.89 0 00.71.29zM32 34.07a3.86 3.86 0 100-7.71 3.86 3.86 0 100 7.71zM24 52V22.29h16V52zm-2.43 4h20.79A1.61 1.61 0 0044 54.36V19.93a1.62 1.62 0 00-1.64-1.65H21.57A1.6 1.6 0 0020 19.93v34.43A1.6 1.6 0 0021.57 56z" />
    </svg>
  );
}

export default SvgRemoteOutlined;
