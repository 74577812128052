import * as React from "react";

function SvgClockCircleOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M31 34h12a1 1 0 001-1v-2a1 1 0 00-1-1h-9V17a1 1 0 00-1-1h-2a1 1 0 00-1 1v16a1 1 0 001 1zm1 19a21 21 0 1121-21 21 21 0 01-21 21zm0 4A25 25 0 107 32a25 25 0 0025 25z" />
    </svg>
  );
}

export default SvgClockCircleOutlined;
