import * as React from "react";

function SvgCampaignPdfFilled(props) {
  return (
    <svg
      id="CampaignPdfFilled_svg__Icons"
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      {...props}
    >
      <defs />
      <path
        className="CampaignPdfFilled_svg__cls-1"
        d="M31 30v-4h-3v12h8v-7h-4a1 1 0 01-1-1z"
      />
      <path
        className="CampaignPdfFilled_svg__cls-1"
        d="M50.72 16H13.28A3.25 3.25 0 0010 19.14v25.72A3.25 3.25 0 0013.28 48h37.44A3.2 3.2 0 0054 44.86V19.14A3.2 3.2 0 0050.72 16zM38 39a1 1 0 01-1 1H27a1 1 0 01-1-1V25a1 1 0 011-1h5.57a.83.83 0 01.72.29l4.42 4.42a.92.92 0 01.29.72z"
      />
    </svg>
  );
}

export default SvgCampaignPdfFilled;
