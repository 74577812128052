import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./polyfills";

import loadable from "@loadable/component";

import { BrowserRouter } from "react-router-dom";
import { Spin } from "styleguide";
import { retry } from "./utils";

import { createRoot } from "react-dom/client";
const container = document.querySelector('[data-react-ui="root"]');
const root = createRoot(container!);

const ScreenOrderFormApp = loadable(
  () => retry(import("./app/screenOrderForm/ScreenOrderFormApp")),
  {
    fallback: <Spin />
  }
);
const ScreenAssociateFormApp = loadable(
  () => retry(import("./app/screenAssociateForm/ScreenAssociateFormApp")),
  {
    fallback: <Spin />
  }
);
const DiagnosticApp = loadable(
  () => retry(import("./app/diagnosticForm/DiagnosticApp")),
  {
    fallback: <Spin />
  }
);
const GestionApp = loadable(() => retry(import("./app/gestion/GestionApp")), {
  fallback: <Spin />
});
const UnsubscribeSuccessful = loadable(
  () => retry(import("./app/pages/Unsubscribe/UnsubscribeSuccessful")),
  {
    fallback: <Spin />
  }
);

// Try to render a React component in the DOM based on a specific route
try {
  root.render(
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/screens/order/*" element={<ScreenOrderFormApp />} />
          <Route
            path="/screens/associate/*"
            element={<ScreenAssociateFormApp />}
          />
          <Route path="/diagnostic/*" element={<DiagnosticApp />}></Route>
          <Route
            path="/unsubscribe/successful"
            element={<UnsubscribeSuccessful />}
          ></Route>
          <Route path="*" element={<GestionApp />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
} catch (e) {
  // eslint-disable-next-line
  console.error(e);
}
