/**
 * This file contains options for notifications
 * from NotificationContext created from Antd (see GestionApp.jsx)
 * DO NOT use it with Antd Alert notification or Semantic-ui Message
 */

import React from "react";
import { FormattedMessage } from "react-intl";
import { default as SupportFormLink } from "styleguide/SupportFormLink";
import * as Icon from "@ant-design/icons";
import { green, cenareoColors } from "styleguide/colors";
import { CenareoCustomIcon } from "styleguide/Icon";
import { CheckCircleOutlined } from "styleguide/Icon/cenareo";
import { CloseCircleOutlined } from "@ant-design/icons";

const successColor = green[5];
const errorColor = cenareoColors.red.primary;

export const defaultOptions = {
  placement: "topRight",
  duration: 10
};

export const successNotificationOptions = {
  icon: (
    <CenareoCustomIcon
      component={CheckCircleOutlined}
      style={{ color: successColor }}
    />
  )
};

export const errorNotificationOptions = {
  icon: <CloseCircleOutlined style={{ color: errorColor }} />
};

export const networkErrorNotificationOptions = {
  message: <FormattedMessage id="generic.error.networkError.title" />,
  description: (
    <FormattedMessage
      id="generic.error.networkError.description"
      values={{ link: <SupportFormLink /> }}
    />
  ),
  icon: <Icon.ApiFilled style={{ color: errorColor }} />
};

export const serverErrorNotificationOptions = {
  message: <FormattedMessage id="generic.error.serverError.title" />,
  description: (
    <FormattedMessage
      id="generic.error.serverError.description"
      values={{ link: <SupportFormLink /> }}
    />
  ),
  icon: <Icon.DatabaseFilled style={{ color: errorColor }} />
};
