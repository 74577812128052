import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "filepicker.cropper.title",
    defaultMessage: "Resize media"
  },
  callToAction: {
    id: "filepicker.cropper.callToAction",
    defaultMessage: "Resize"
  },
  scaleFactor: {
    id: "filepicker.cropper.scaleFactor",
    defaultMessage: "Scale factor"
  },
  fillArea: {
    id: "filepicker.cropper.fillArea",
    defaultMessage: "Fill area"
  },
  fitArea: {
    id: "filepicker.cropper.fitArea",
    defaultMessage: "Fit area"
  },
  zoomIn: {
    id: "filepicker.cropper.zoomIn",
    defaultMessage: "Zoom in"
  },
  zoomOut: {
    id: "filepicker.cropper.zoomOut",
    defaultMessage: "Zoom out"
  },
  rotateLeft: {
    id: "filepicker.cropper.rotateLeft",
    defaultMessage: "Rotate counterclockwise"
  },
  rotateRight: {
    id: "filepicker.cropper.rotateRight",
    defaultMessage: "Rotate clockwise"
  },
  targetDimensions: {
    id: "filepicker.cropper.targetDimensions",
    defaultMessage: "Target dimensions:"
  },
  notEnoughPixelsWarning: {
    id: "filepicker.cropper.notEnoughPixelsWarning",
    defaultMessage:
      "The selected area ({currentWidth} by {currentHeight} pixels) is smaller than your target dimensions ({targetWidth} by {targetHeight} pixels). The content will be scaled up, and may appear pixelated."
  }
});
