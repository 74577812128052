import {
  APIRequestBag,
  SafeAPIRequestBag,
  BaseAPIRequestArguments,
  PaginatedResourceListResponse
} from "./types";
import { useCallback, useMemo } from "react";
import { FilterType } from "styleguide/DataTable/filtering";

export const EMPTY_ARRAY: any[] = [];

export function nullIsEmptyArray<ResourceType>(
  value: ResourceType[] | null
): ResourceType[] {
  return value || EMPTY_ARRAY;
}

export type QueryParams = Record<
  string,
  string | number | (string | number)[] | undefined
>;

export function usePrepareQueryParams(
  endpoint: string,
  queryParams?: QueryParams
): () => BaseAPIRequestArguments {
  return useCallback(() => {
    const params = new URLSearchParams();

    if (queryParams) {
      Object.entries(queryParams).forEach(([key, val]) => {
        if (!val) return;
        if (Array.isArray(val)) {
          val.forEach((valItem) => {
            params.append(key, valItem.toString());
          });
        } else {
          params.append(key, val.toString());
        }
      });
    }
    const stringParams = params.toString();

    return [stringParams ? endpoint + "?" + stringParams : endpoint];
  }, [endpoint, queryParams]);
}

export function usePrepareRequest<
  RecordType extends any,
  InnerArgs extends any[] = any[],
  OuterArgs extends any[] = any[]
>(
  requestBag: APIRequestBag<RecordType, InnerArgs>,
  paramsTransformer: (...input: OuterArgs) => InnerArgs
): APIRequestBag<RecordType, OuterArgs> {
  const [state, baseCallback] = requestBag;

  const callback = useCallback(
    async (...args: any) => {
      const outArgs = paramsTransformer(...args);
      return await baseCallback(...outArgs);
    },
    [baseCallback, paramsTransformer]
  );

  return useMemo(() => {
    const requestBag: APIRequestBag<RecordType, OuterArgs> = [state, callback];

    return requestBag;
  }, [callback, state]);
}

/**
 * Prepare arguments to a data request call.
 *
 * @param requestBag the RequestBag with the fetch function to prepare
 * @param paramsTransformer a function that accepts any arguments and returns the list of arguments to pass to the fetch function
 */
export function usePrepareSafeRequest<
  RecordType = any,
  InnerArgs extends any[] = any[],
  OuterArgs extends any[] = any[]
>(
  requestBag: SafeAPIRequestBag<RecordType, InnerArgs>,
  paramsTransformer: (...input: OuterArgs) => InnerArgs
): SafeAPIRequestBag<RecordType, OuterArgs> {
  const [state, baseCallback] = requestBag;

  const callback = useCallback(
    async (...args: any) => {
      const outArgs = paramsTransformer(...args);
      return await baseCallback(...outArgs);
    },
    [baseCallback, paramsTransformer]
  );

  return useMemo(() => {
    const requestBag: SafeAPIRequestBag<RecordType, OuterArgs> = [
      state,
      callback
    ];

    return requestBag;
  }, [callback, state]);
}

/**
 * Apply a deserializer function to the return value of a RequestBag.
 * @param requestBag
 * @param deserializer
 */
export function useDeserializer<T = any, A extends any[] = any[], U = any>(
  requestBag: APIRequestBag<T, A>,
  deserializer: (data: T | null) => U
): SafeAPIRequestBag<U, A> {
  const [state, callback] = requestBag;

  const wrappedCallback = useCallback(
    async (...args: A) => {
      const response = await callback(...args);

      return deserializer(response);
    },
    [callback, deserializer]
  );

  return useMemo(
    () => [
      {
        ...state,
        value: deserializer(state.value)
      },
      wrappedCallback
    ],
    [deserializer, state, wrappedCallback]
  );
}

/**
 * Take a dictionary and return it as a valid query params string.
 *
 * Array values are inlined as the same key multiple times.
 */
export function toQueryParams(input: Record<string, any>): string | null {
  const urlSearchParams = new URLSearchParams();
  Object.entries(input).forEach((current) => {
    const [filterName, filterValue] = current;
    if (Array.isArray(filterValue)) {
      filterValue.forEach((value) => {
        urlSearchParams.append(filterName, value);
      });
    } else {
      urlSearchParams.append(filterName, filterValue);
    }
  });

  return urlSearchParams.toString() || null;
}

export function prepareSearch(searchTerm: string): [FilterType] {
  return [
    {
      filter: { search: searchTerm }
    }
  ];
}

export function skipPagination<ResourceType>(
  value: PaginatedResourceListResponse<ResourceType> | null
): ResourceType[] {
  return value?.results || [];
}
