import { Dimensions } from "../UnifiedFilePicker/types";

export interface APIFileOrDirectory {
  url: string;
  mime_type: string;
  name: string;
  preview_url?: string;
  user_url?: string;
  size?: number;
  dimensions?: Dimensions;
}

const doGetRequest = async (
  url: string,
  params: Record<string, string> = {},
  headers: Record<string, string> = {}
) => {
  const fullUrl =
    url +
    "?" +
    Object.entries(params)
      .reduce((acc, [key, val]) => [...acc, `${key}=${val}`], [] as string[])
      .join("&");
  const response = await fetch(fullUrl, {
    headers: new Headers(headers)
  });

  if (!response.ok) {
    const errorBody = await response.json();
    const fullError = {
      statusCode: response.status,
      body: errorBody
    };
    throw Error(JSON.stringify(fullError));
  }
  return await response.json();
};

export const fetchDirectoryContents = async (
  externalStorageName: string,
  accept: string | undefined,
  directoryUrl: string | undefined,
  pageId?: string | null
): Promise<{ items: APIFileOrDirectory[]; nextPageId?: string }> => {
  return await doGetRequest("/external-media/", {
    storage: externalStorageName,
    ...(directoryUrl ? { directoryUrl } : {}),
    ...(accept ? { accept } : {}),
    ...(pageId ? { pageId } : {})
  });
};

export const doSearch = async (
  externalStorageName: string,
  accept: string | undefined,
  query: string | undefined,
  pageId?: string | null
): Promise<{ items: APIFileOrDirectory[]; nextPageId?: string }> => {
  return await doGetRequest("/external-media/search", {
    storage: externalStorageName,
    ...(query ? { query } : {}),
    ...(accept ? { accept } : {}),
    ...(pageId ? { pageId } : {})
  });
};
