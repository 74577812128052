import * as React from "react";

function SvgSearchFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M53 44.51l-9.61-9.61a18.37 18.37 0 10-8.49 8.49L44.51 53a1.72 1.72 0 002.42 0L53 46.93a1.72 1.72 0 000-2.42zm-16.64-8.15a13.42 13.42 0 110-19 13.29 13.29 0 010 19z" />
    </svg>
  );
}

export default SvgSearchFilled;
