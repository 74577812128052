import React from "react";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import { FormattedMessage } from "react-intl";
import { Breadcrumb as AntBreadcrumb } from "antd";
import { NeutralLink } from "styleguide/Links";
import { useMobile } from "styleguide/mobile";

export interface BreadcrumbStep {
  label: React.ReactNode;
  icon?: React.ReactNode | false;
  path: string;
}

interface Props {
  basePath: string;
  steps: BreadcrumbStep[];
  startLabel?: React.ReactNode;
}

const IconBreadcrumb: React.FC<Props> = ({
  basePath,
  steps,
  startLabel = <FormattedMessage id="generic.start" />
}) => {
  const isMobile = useMobile();

  const homeStep: BreadcrumbStep = {
    icon: <HomeOutlined />,
    label: startLabel,
    path: ""
  };

  const allSteps = [homeStep, ...steps];
  const lastStep = allSteps.pop() as BreadcrumbStep;
  const allButLastSteps = allSteps;

  return (
    <AntBreadcrumb style={{ marginBottom: "8px" }}>
      {allButLastSteps.map((step) => (
        <AntBreadcrumb.Item key={step.path}>
          <NeutralLink
            to={basePath + step.path}
            className="ant-breadcrumb-link"
          >
            {step.icon}
            {!isMobile && (
              <span style={{ marginLeft: "4px" }}>{step.label}</span>
            )}
          </NeutralLink>
        </AntBreadcrumb.Item>
      ))}
      <AntBreadcrumb.Item>
        {lastStep.icon}
        <span style={{ marginLeft: "4px" }}>{lastStep?.label}</span>
      </AntBreadcrumb.Item>
    </AntBreadcrumb>
  );
};

export default IconBreadcrumb;
