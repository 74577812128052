export const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "asx",
  "avi",
  "dv",
  "flv",
  "f4v",
  "m4v",
  "mkv",
  "mov",
  "moov",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mts",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "webm",
  "wmv",
  "vob"
];

export const imageExtensions = [
  "bmp",
  "gif",

  "jfi",
  "jfif",
  "jif",
  "jpe",
  "jpeg",
  "jpg",

  "png",
  "tif",
  "tiff",

  "svg",
  "svgz"
];

export const documentExtensions = ["pdf"];

export const excelMimeTypes = [
  "application/vnd.ms-excel",
  "application/cdfv2",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

export const supportedMedias = [
  ...videoExtensions,
  ...imageExtensions,
  ...documentExtensions
];

/**
 * Check if a file is a video using its name or its media type.
 *
 * @param filename The name of the file
 * @param mediaType The media type of the file. Optional.
 * @returns true if the file is a video. false otherwise.
 */
export function isVideo(filename: string, mediaType?: string) {
  if (mediaType) {
    return mediaType.startsWith("video/");
  }

  const extension = extractExtension(filename);
  return extension && videoExtensions.includes(extension);
}

/**
 * Check if a file is an image using its name or its media type.
 *
 * @param filename The name of the file
 * @param mediaType The media type of the file. Optional.
 * @returns true if the file is an image. false otherwise.
 */
export function isImage(filename: string, mediaType?: string) {
  if (mediaType) {
    return mediaType.startsWith("image/");
  }

  const extension = extractExtension(filename);
  return extension && imageExtensions.includes(extension);
}

export function isPDF(filename: string, mediaType?: string) {
  if (mediaType) {
    return mediaType === "application/pdf";
  }

  const extension = extractExtension(filename);
  return extension && documentExtensions.includes(extension);
}

export function isExcel(filename: string, mediaType?: string) {
  return mediaType && excelMimeTypes.includes(mediaType);
}

export function isCSV(filename: string, mediaType?: string) {
  return mediaType === "text/csv";
}

export function isGoogleSheet(filename: string, mediaType?: string) {
  return mediaType === "application/vnd.google-apps.spreadsheet";
}

// accept field reference:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
const matchesAcceptEntry = (
  acceptEntry: string,
  filename: string,
  mediaType?: string
) => {
  if (acceptEntry === "video/*") return isVideo(filename, mediaType);
  if (acceptEntry === "image/*") return isImage(filename, mediaType);

  if (acceptEntry.includes("/")) {
    // acceptEntry is a media type
    if (mediaType) return acceptEntry === mediaType;
    const extension = extractExtension(filename);
    return extension && acceptEntry.includes(extension);
  }

  // acceptEntry is for instance '.mp4'
  const extension = extractExtension(filename);
  return acceptEntry.replace(".", "") === extension;
};

export function isSupported(
  filename: string,
  mediaType?: string,
  accept?: string
) {
  if (!accept) return true;

  return accept
    .split(",")
    .some((acceptEntry) =>
      matchesAcceptEntry(acceptEntry.trim(), filename, mediaType)
    );
}

/**
 * Extract the extension of a file using its name
 *
 * @param filename The name of the file to check
 * @returns the extension (ex: "jpg"). false if the extension is not found.
 */
export function extractExtension(filename: string) {
  const match = filename.match(/\.([a-zA-Z0-9]{2,4})$/);
  if (!match) {
    return false;
  }
  return match[1];
}
