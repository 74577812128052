import { defineMessages } from "react-intl";

export default defineMessages({
  placeholder: {
    id: "filepicker.placeholder",
    defaultMessage: "Choose {multiple, select, false {a file} other {files}}"
  },
  changeFiles: {
    id: "filepicker.changeFiles",
    defaultMessage: "Change {multiple, select, false {file} other {files}}"
  },
  createFromTemplate: {
    id: "filepicker.createFromTemplate",
    defaultMessage: "Create a new document"
  },
  useExistingFile: {
    id: "filepicker.useExistingFile",
    defaultMessage: "Use an existing document"
  },
  chooseFromMediaLibrary: {
    id: "filepicker.chooseFromMediaLibrary",
    defaultMessage: "Choose from your media library"
  },
  fromDevice: {
    id: "filepicker.fromDevice",
    defaultMessage:
      "Choose {multiple, select, false {a file} other {files}} from this phone"
  },
  fromComputer: {
    id: "filepicker.fromComputer",
    defaultMessage:
      "Choose {multiple, select, false {a file} other {files}} from this computer"
  },
  preview: {
    id: "filepicker.preview",
    defaultMessage: "Media preview"
  },
  pickFileToPreview: {
    id: "filepicker.pickFileToPreview",
    defaultMessage: "Pick a file to preview it here."
  },
  deleteFile: {
    id: "filepicker.deleteFile",
    defaultMessage: "Delete this file"
  },
  resizeFile: {
    id: "filepicker.resizeFile",
    defaultMessage: "Resize this file"
  },
  croppingRequired: {
    id: "filepicker.croppingRequired",
    defaultMessage:
      "Some files above require resizing. You can use the cropper if needed."
  },
  error: {
    id: "filepicker.error",
    defaultMessage: "An error occurred"
  },
  "errors.multiPagePDFUnavailable": {
    id: "filepicker.errors.multiPagePDFUnavailable",
    defaultMessage: "Multi-page PDFs are not supported by your fleet."
  },
  "errors.PDFUnreadable": {
    id: "filepicker.errors.PDFUnreadable",
    defaultMessage: "PDF file is unreadable, it might be encrypted."
  },
  "errors.empty": {
    id: "filepicker.errors.empty",
    defaultMessage: "The submitted file is empty."
  },
  "errors.max_length": {
    id: "filepicker.errors.max_length",
    defaultMessage: "This filename exceeds the maximum allowed length."
  }
});
