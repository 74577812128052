import * as React from "react";

function SvgCheckCircleFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M32 7a25 25 0 1025 25A25 25 0 0032 7zm11.14 21.29L30.57 40.64a1.12 1.12 0 01-.78.36 1.16 1.16 0 01-.86-.36l-8.07-7.93a1.28 1.28 0 01-.36-.85 1.14 1.14 0 01.36-.79L24 27.79a1.2 1.2 0 011.71 0l4.08 4 8.5-8.43a1.11 1.11 0 01.85-.36 1 1 0 01.86.36l3.14 3.28a.91.91 0 01.36.79 1.14 1.14 0 01-.36.86z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheckCircleFilled;
