import { CloseOutlined, HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Input, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMobile } from "styleguide/mobile";
import { RemoteDirectoryDescriptor } from "./ExternalFilePicker";
import messages from "./intl";

export interface BreadcrumbItem {
  url: string;
  label: string;
}

export interface SearchQuery {
  query: string;
  label: string;
}

export type Location = BreadcrumbItem | SearchQuery;

export function isSearchQuery(
  location: Location | null
): location is SearchQuery {
  return !!location && !!(location as SearchQuery).query;
}

export function getBreadcrumbItem(
  directory: RemoteDirectoryDescriptor
): BreadcrumbItem {
  return {
    url: directory.url,
    label: directory.name
  };
}

interface Props {
  breadcrumb: Location[];
  searchAvailable: boolean;
  setBreadcrumb: React.Dispatch<React.SetStateAction<Location[]>>;
}

const BreadcrumbSearch: React.FC<Props> = ({
  breadcrumb,
  setBreadcrumb,
  searchAvailable
}) => {
  const intl = useIntl();
  const [mode, setMode] = useState<"breadcrumb" | "search">("breadcrumb");
  const clearSearch = () => {
    setMode("breadcrumb");
    setBreadcrumb(previousBreadcrumb.current);
  };
  const previousBreadcrumb = useRef<Location[]>([]);
  const isMobile = useMobile();

  useEffect(() => {
    if (mode === "search") {
      previousBreadcrumb.current = breadcrumb;
    }
  }, [breadcrumb, mode]);

  return (
    <div
      style={{ display: "flex", alignItems: "center", paddingRight: "16px" }}
    >
      {mode === "breadcrumb" && (
        <>
          <Breadcrumb
            style={{
              flexGrow: 1,
              marginLeft: "20px",
              position: "relative",
              top: "4px"
            }}
          >
            <Breadcrumb.Item
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setBreadcrumb([]);
              }}
            >
              <HomeOutlined style={{ fontSize: "16px" }} />
            </Breadcrumb.Item>
            {isMobile
              ? breadcrumb.length > 0 && (
                  <>
                    {breadcrumb.length > 1 && (
                      <Breadcrumb.Item
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setBreadcrumb((breadcrumb) =>
                            breadcrumb.slice(0, breadcrumb.length - 1)
                          );
                        }}
                      >
                        ...
                      </Breadcrumb.Item>
                    )}
                    <Breadcrumb.Item>
                      {breadcrumb[breadcrumb.length - 1].label}
                    </Breadcrumb.Item>
                  </>
                )
              : breadcrumb.map((subPath, idx) => {
                  return (
                    <Breadcrumb.Item
                      key={idx}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setBreadcrumb((breadcrumb) =>
                          breadcrumb.slice(0, idx + 1)
                        );
                      }}
                    >
                      {subPath.label}
                    </Breadcrumb.Item>
                  );
                })}
          </Breadcrumb>
          {searchAvailable && (
            <Tooltip title={<FormattedMessage {...messages.searchButton} />}>
              <Button
                shape="circle"
                icon={<SearchOutlined />}
                onClick={() => setMode("search")}
              />
            </Tooltip>
          )}
        </>
      )}
      {mode === "search" && (
        <>
          <Input.Search
            autoFocus
            onSearch={(query) => {
              setBreadcrumb([
                {
                  label: intl.formatMessage(
                    messages.searchBreadcrumbItemLabel,
                    { query }
                  ),
                  query
                }
              ]);
              setMode("breadcrumb");
            }}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            style={{ flexGrow: 1 }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                clearSearch();
                e.stopPropagation();
              }
            }}
            enterButton
          />
          <Tooltip title={<FormattedMessage id="generic.cancel" />}>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              onClick={clearSearch}
              style={{ marginLeft: "16px" }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default BreadcrumbSearch;
