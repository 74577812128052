import React from "react";

import { FormattedMessage } from "react-intl";

import cenareo from "./cenareo.png";

import { Layout, Menu } from "antd";
import * as Icon from "@ant-design/icons";

export const DesktopMenu = ({ onChange, languages, helpUrl }) => {
  return (
    <Layout.Header className="cenareo-ant-header">
      <div>
        <img
          src={cenareo}
          alt="Cenareo logo"
          className="cenareo-ant-header-logo"
        />
      </div>
      <Menu mode="horizontal">
        <Menu.Item key="help">
          <a
            href={helpUrl}
            type="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="helper.message" />
          </a>
        </Menu.Item>
        <Menu.SubMenu
          title={
            <span className="submenu-title-wrapper">
              <Icon.GlobalOutlined />
              <FormattedMessage id="generic.changeLanguage" />
            </span>
          }
        >
          {languages.map(({ value, label }) => (
            <Menu.Item key={value} onClick={() => onChange(value)}>
              {label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  );
};

export default DesktopMenu;
