import React, { useState } from "react";
import { Card, Tooltip } from "antd";

export const CardWithTooltip = ({ tooltipProps, cardProps, cardMetaProps }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleEnter = (e) => {
    if (e.target.offsetWidth < e.target.scrollWidth) {
      setTooltipVisible(true);
    }
  };

  const handleLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <Card {...cardProps}>
      <Tooltip {...tooltipProps} visible={tooltipVisible} placement={"top"}>
        <Card.Meta
          {...cardMetaProps}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        />
      </Tooltip>
    </Card>
  );
};
