import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Upload } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMobile } from "styleguide/mobile";
import translations from "../UnifiedFilePicker/intl";
import {
  FileStatus,
  FileWidgetProps,
  LocalFileDescriptor
} from "../UnifiedFilePicker/types";
import { analyze } from "../UnifiedFilePicker/utils";

type Props = FileWidgetProps<LocalFileDescriptor>;

const emptyInstance: LocalFileDescriptor = {
  type: "local",
  name: "",
  status: FileStatus.NEW,
  uploadProgress: 0,
  localId: Date.now().toString()
};

const LocalFilePicker: React.FC<Props> = ({
  accept,
  multiple = false,
  doUpload,
  onPreUpload,
  onAnalyzed,
  onUploadSuccess,
  onUploadError
}) => {
  const intl = useIntl();
  const isMobile = useMobile();

  const handleFile = useCallback(
    async (file: RcFile) => {
      if (!file) return;

      const preUploadInstance: LocalFileDescriptor = {
        ...emptyInstance,
        name: file.name,
        mediaType: file.type,
        localId: file.uid,
        fileInstance: file,
        status: !!doUpload ? FileStatus.LOADING : FileStatus.NEW
      };
      delete preUploadInstance.error;

      if (onPreUpload) {
        onPreUpload(preUploadInstance);
      }

      const { dimensions, previewUrl } = await analyze(
        preUploadInstance,
        intl.locale
      );
      const analyzedInstance = {
        ...preUploadInstance,
        status: FileStatus.ANALYZED,
        dimensions,
        previewUrl
      };
      if (onAnalyzed) {
        onAnalyzed(analyzedInstance);
      }

      if (doUpload) {
        try {
          const mediaId = await doUpload(analyzedInstance);
          const postUploadInstance: LocalFileDescriptor = {
            ...analyzedInstance,
            backendId: mediaId,
            status: FileStatus.SUCCESS
          };

          if (onUploadSuccess) {
            onUploadSuccess(postUploadInstance);
          }
        } catch (error) {
          const postErrorInstance: LocalFileDescriptor = {
            ...analyzedInstance,
            status: FileStatus.ERROR,
            error: error as Error
          };
          if (onUploadError) {
            onUploadError(postErrorInstance);
          }
        }
      } else {
        if (onUploadSuccess) {
          onUploadSuccess(analyzedInstance);
        }
      }
    },
    [
      doUpload,
      intl.locale,
      onAnalyzed,
      onPreUpload,
      onUploadError,
      onUploadSuccess
    ]
  );

  return (
    <Row style={{ marginBottom: "12px" }}>
      <Col span={24}>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Divider>
              <FormattedMessage id="generic.or" />
            </Divider>
            <Upload
              name="file"
              multiple={multiple}
              accept={accept}
              beforeUpload={(file) => {
                handleFile(file);
                return false;
              }}
              data-bfi-disabled
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} size="large">
                <span>
                  <FormattedMessage
                    {...translations.fromDevice}
                    values={{ multiple }}
                  />
                </span>
              </Button>
            </Upload>
            <Divider />
          </div>
        ) : (
          <>
            <h4 style={{ marginBottom: "12px" }}>
              <FormattedMessage
                {...translations.fromComputer}
                values={{ multiple }}
              />
            </h4>
            <Upload.Dragger
              name="file"
              multiple={multiple}
              accept={accept}
              showUploadList={false}
              beforeUpload={(file) => {
                handleFile(file);
                return false;
              }}
              data-bfi-disabled
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <FormattedMessage
                  id="filepicker.dropzone.placeholder"
                  values={{ multiple }}
                />
              </p>
            </Upload.Dragger>
          </>
        )}
      </Col>
    </Row>
  );
};

export default LocalFilePicker;
