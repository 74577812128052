import styled from "styled-components";
import { Link } from "react-router-dom";

/*
Remove browser styles from the <a> tag of a Link
*/
const aReset = `
  text-decoration: none;
  color: initial;

  &:hover, &:focus {
    text-decoration: none;
    color: initial;
  }

  // fix separator between Ant list items that may be children of this component
  &:not(:last-child) > .ant-list-item {
    border-bottom: 1px solid #f0f0f0;
  }
`;
export const NeutralAnchor = styled.a`
  ${aReset}
`;

export const NeutralLink = styled(Link)`
  ${aReset}
`;
