import * as React from "react";

function SvgCampaignVideoFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M28.14 38A1.17 1.17 0 0127 36.86v-9.72A1.17 1.17 0 0128.14 26a1.29 1.29 0 01.5.14L36.5 31a1.25 1.25 0 010 2l-7.86 4.86a1.29 1.29 0 01-.5.14zM13.28 48h37.44A3.2 3.2 0 0054 44.86V19.14A3.2 3.2 0 0050.72 16H13.28A3.25 3.25 0 0010 19.14v25.72A3.25 3.25 0 0013.28 48z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCampaignVideoFilled;
