import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

export function retry(
  promise: Promise<any>,
  retriesLeft = 5,
  interval = 1000
): Promise<any> {
  return new Promise((resolve, reject) => {
    promise.then(resolve).catch((error: any) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          // reject('maximum retries exceeded');
          reject(error);
          return;
        }

        // Passing on "reject" is the important part
        retry(promise, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
  });
}

export function useDebounce(fn: Function, delay: number) {
  const countdown = useRef<number | undefined>();

  return useCallback(() => {
    if (countdown.current) {
      clearTimeout(countdown.current);
      countdown.current = undefined;
    }
    countdown.current = setTimeout(fn, delay);
  }, [delay, fn]);
}

export function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useDebouncedState<T>(
  initialValue: T,
  timeout = 200
): [T, T, React.Dispatch<React.SetStateAction<T>>] {
  const [liveValue, setLiveValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const timer = useRef<NodeJS.Timeout>();

  const debouncedSetValue = useCallback(
    (val: T) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setDebouncedValue(val), timeout);
    },
    [timeout]
  );

  useEffect(() => {
    debouncedSetValue(liveValue);
  }, [liveValue, debouncedSetValue]);

  return [liveValue, debouncedValue, setLiveValue];
}
