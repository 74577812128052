import React, { useEffect, createContext } from "react";
import { FormattedMessage } from "react-intl";
import { Message } from "semantic-ui-react";
import { Alert } from "antd";

type Notif = {
  error: any;
  id: any;
  values: any;
};

const LegacyNotificationUI = ({
  notification,
  sendNotification
}: {
  notification: Notif;
  sendNotification: any;
}) => (
  <Message
    success={!notification.error}
    error={notification.error}
    size="large"
    onDismiss={() => sendNotification(null)}
  >
    <FormattedMessage
      id={notification.id ? notification.id : "error"}
      values={notification.values}
    />
  </Message>
);

const NotificationUI = ({ notification }: { notification: any }) => (
  <Alert
    closable
    message={
      <FormattedMessage
        id={notification.id ? notification.id : "error"}
        values={notification.values}
      />
    }
    description={
      notification.description ? (
        <FormattedMessage
          id={notification.description}
          values={notification.description_values}
        />
      ) : null
    }
    type={notification.error ? "error" : "success"}
    showIcon
  />
);

export const NotificationContext = createContext({
  openNotification: ({
    message,
    description
  }: {
    message: any;
    description?: any;
  }) => {
    // This is overridden later
    console.info(`sent notification: ${message} ${description}`);
  }
});

const Notification = ({
  notification,
  sendNotification,
  legacy
}: {
  notification: Notif;
  sendNotification: Function;
  legacy: boolean;
}) => {
  // Remove the notification with setTimeout and clear it with no re-render
  useEffect(() => {
    const timer = setTimeout(() => {
      sendNotification(null);
    }, 20000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification, sendNotification]);

  return legacy ? (
    <LegacyNotificationUI
      notification={notification}
      sendNotification={sendNotification}
    />
  ) : (
    <NotificationUI notification={notification} />
  );
};

export default Notification;
