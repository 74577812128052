import * as React from "react";

function SvgTagOutlined(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path d="M35.14 46.29a1.13 1.13 0 00.72.28 1.51 1.51 0 00.71-.28l9.79-9.79a1.51 1.51 0 00.28-.71 1.1 1.1 0 00-.28-.72l-1.43-1.43a1.31 1.31 0 00-.72-.28 1.51 1.51 0 00-.71.28l-9.79 9.79a1.51 1.51 0 00-.28.71 1.31 1.31 0 00.28.72zM20 23a3 3 0 10-3-3 3 3 0 003 3zm15.86 30.14L10 27.29V10h17.29l25.85 25.86zm-1.36 4.29a2 2 0 001.36.57 1.91 1.91 0 001.35-.57l20.22-20.22a1.91 1.91 0 00.57-1.35 2 2 0 00-.57-1.36L29.5 6.57A2 2 0 0028.14 6H7.93A1.92 1.92 0 006 7.93v20.21a2 2 0 00.57 1.36z" />
    </svg>
  );
}

export default SvgTagOutlined;
