import { Layout } from "antd";
import React, { FC, useEffect, useState } from "react";

import { Col, Menu, Row, Typography } from "antd";
import { SelectEventHandler } from "rc-menu/lib/interface";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

interface PageProps {
  icon?: React.ReactNode;
  component: React.ReactNode;
  route: string;
  title: string | React.ReactNode;
}

interface FullPageLayoutProps {
  navigationTitle: string | React.ReactNode;
  pages: PageProps[];
}

interface FullPageLayoutChildrenProps {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  children?: React.ReactNode;
}

export const FullPageLayoutChildren: FC<FullPageLayoutChildrenProps> = (
  props
) => {
  return (
    <Row className="layout-flex-row">
      <Col span={12} className="layout-title-container">
        <Typography.Title>{props.title}</Typography.Title>
        <Typography.Title level={2}>{props.subTitle}</Typography.Title>
      </Col>
      {props.children}
    </Row>
  );
};

const FullPageLayout: FC<FullPageLayoutProps> = ({
  pages,
  navigationTitle
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);

  const handleMenuSelection: SelectEventHandler = ({ key }) => {
    setSelectedMenu(key);
    navigate(key);
  };

  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const lastPart = pathnameParts[pathnameParts.length - 1];
    setSelectedMenu(lastPart);
  }, [location.pathname]);

  return (
    <Layout className="layout-main">
      <Layout.Sider width={200}>
        <div className="menu-title">
          <Typography.Title level={4}>{navigationTitle}</Typography.Title>
        </div>
        <Menu
          mode="inline"
          selectedKeys={selectedMenu ? [selectedMenu] : []}
          onSelect={handleMenuSelection}
          items={pages.map(({ title, icon, route }) => ({
            key: route,
            icon,
            label: title
          }))}
        />
      </Layout.Sider>
      <Layout className="layout-content-background">
        <Layout.Content className="layout-content">
          <Routes>
            {pages.map(({ route, component }) => {
              return <Route key={route} path={route} element={component} />;
            })}
          </Routes>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default FullPageLayout;
