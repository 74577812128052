import * as React from "react";

function SvgDeleteFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M30 12.86a2 2 0 012-1.93 2 2 0 012 1.93zm-16 7.21h36a2 2 0 002-2v-3.21a1.92 1.92 0 00-1.93-1.93H38a6 6 0 00-12 0H13.93A1.92 1.92 0 0012 14.86v3.21a2 2 0 002 2zM39 51a1 1 0 01-1-1V31.07a1 1 0 011-1h2a1 1 0 011 1V50a1 1 0 01-1 1zm-8 0a1 1 0 01-1-1V31.07a1 1 0 011-1h2a1 1 0 011 1V50a1 1 0 01-1 1zm-8 0a1 1 0 01-1-1V31.07a1 1 0 011-1h2a1 1 0 011 1V50a1 1 0 01-1 1zm-6.43 6h30.86a2 2 0 001.93-1.93v-29a2 2 0 00-2-2H16.64a2 2 0 00-2 2v29A1.92 1.92 0 0016.57 57z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDeleteFilled;
