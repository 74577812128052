import React, { useCallback, useState } from "react";

import { Button, Card, Empty } from "antd";
import { FieldProps } from "formik";
import { FormattedMessage } from "react-intl";
import ButtonLikeAnAnchor from "styleguide/ButtonLikeAnAnchor";
import { useAntFormikEventHandlers } from "styleguide/formik-ant-bindings";
import UnifiedFilePicker from "../UnifiedFilePicker";
import { FileDescriptor, FileStatus, Props } from "../UnifiedFilePicker/types";

const FormikFilePicker: React.FC<Props & FieldProps["field"]> = ({
  onChange,
  value,
  name,
  onBlur,
  ...unifiedFilePickerProps
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { rewiredOnChange } = useAntFormikEventHandlers(
    "string",
    name,
    onChange,
    onBlur
  );

  const setFilesAndHide: React.Dispatch<
    React.SetStateAction<FileDescriptor[]>
  > = useCallback(
    (arg) => {
      const newValue = typeof arg === "function" ? arg(value)[0] : arg[0];
      if (newValue && newValue.status === FileStatus.ANALYZED) {
        setVisible(false);
      }
      rewiredOnChange(newValue);
    },
    [rewiredOnChange, setVisible, value]
  );
  if (visible) {
    return (
      <div
        style={{
          border: "1px solid lightgray",
          margin: 10,
          padding: 10
        }}
      >
        <UnifiedFilePicker
          {...unifiedFilePickerProps}
          files={value ? [value] : []}
          setFiles={setFilesAndHide}
        />
      </div>
    );
  } else {
    return (
      <Card
        hoverable
        style={{ width: 240, margin: "auto" }}
        cover={
          value && value.previewUrl ? (
            <img
              alt="media preview"
              src={value.previewUrl}
              onClick={() => setVisible((current) => !current)}
            />
          ) : (
            <ButtonLikeAnAnchor
              onClick={() => setVisible((current) => !current)}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
            </ButtonLikeAnAnchor>
          )
        }
      >
        <Button
          style={{ display: "block", margin: "auto" }}
          onClick={() => setVisible((current) => !current)}
        >
          <FormattedMessage id="generic.pickFile" />
        </Button>
      </Card>
    );
  }
};

export default FormikFilePicker;
