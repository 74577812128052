import { useCookies } from "react-cookie";

import DesktopMenu from "./DesktopMenu";

export const ResponsiveMenu = ({ isMobile, helpUrl }) => {
  const [cookies] = useCookies("csrftoken");

  const onChange = (value) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    formData.append("csrfmiddlewaretoken", cookies.csrftoken);
    formData.append("language", value);
    xhr.onload = () => document.location.reload();
    xhr.open("post", `/i18n/setlang/`, true);
    xhr.send(formData);
  };

  const languages = [
    {
      value: "fr",
      label: "Français"
    },
    {
      value: "en",
      label: "English"
    },
    {
      value: "es",
      label: "Español"
    },
    {
      value: "de",
      label: "Deutsch"
    },
    {
      value: "nl",
      label: "Nederlands"
    }
  ];

  return (
    <DesktopMenu onChange={onChange} languages={languages} helpUrl={helpUrl} />
  );
};
