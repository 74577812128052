import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { isVideo } from "tools/content";
import { FileDescriptor } from "../UnifiedFilePicker/types";
import messages from "./intl";

const maxWidth = "100%";
const maxHeight = "180px";

interface Props {
  file: FileDescriptor;
}

const BasicFilePreview: React.FC<Props> = ({ file }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    if (isVideo(file.name, file.mediaType) && !!file.previewUrl) {
      if (videoRef.current) {
        videoRef.current.load();
      }
    }
  }, [file.localId, file.mediaType, file.name, file.previewUrl]);

  const videoRef = useRef<HTMLVideoElement>(null);

  const styles = {
    maxWidth,
    maxHeight,
    display: loading ? "none" : "block",
    margin: "auto"
  };

  return (
    <>
      {loading && (
        <Spin
          style={{
            margin: "auto",
            display: "block"
          }}
        />
      )}
      {isVideo(file.name, file.mediaType) ? (
        <video
          ref={videoRef}
          onCanPlay={() => setLoading(false)}
          loop
          style={styles}
          muted
          autoPlay
        >
          <source src={file.previewUrl} />
        </video>
      ) : (
        <img
          alt={intl.formatMessage(messages.preview)}
          onLoad={() => setLoading(false)}
          style={styles}
          src={file.previewUrl}
        />
      )}
    </>
  );
};

export default BasicFilePreview;
