import React from "react";
import classNames from "classnames";

export const Image = ({ bordered, medium, link, src }) => {
  return (
    <div
      className={classNames({
        "img-container": true,
        bordered: bordered,
        "medium-size": medium,
        "with-link": Boolean(link)
      })}
    >
      <div className="img-margin">
        <div
          onClick={() => {
            if (link) {
              // TODO: this can be improved
              window.location = link;
            }
          }}
          className="img"
          style={{
            backgroundImage: "url(" + src + ")"
          }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
};
