import React from "react";
import { FormattedMessage } from "react-intl";

import { CenareoTableIcon } from "styleguide/Icon";
import { SearchOutlined } from "styleguide/Icon/cenareo";

import { normalizedSearchQuery, findTermInText } from "../isMatch";
import { Popover } from "antd";

export const SearchInList = ({ list, search, children, itemTypeMessageId }) => {
  if (!search) return children;

  const normalizedSearch = normalizedSearchQuery(search);

  const matchingItems = new Set();

  list.forEach((item) => {
    normalizedSearch.forEach((term) => {
      if (findTermInText(item, term)) {
        matchingItems.add(item);
      }
    });
  });

  return search && matchingItems.size ? (
    <React.Fragment>
      <Popover
        content={
          <FormattedMessage
            id="match"
            values={{
              label: (
                <FormattedMessage
                  id={itemTypeMessageId}
                  values={{
                    total: matchingItems.size
                  }}
                />
              )
            }}
          />
        }
      >
        <CenareoTableIcon
          component={SearchOutlined}
          style={{ color: "#fbbd08" }}
        />
      </Popover>
      {children}
    </React.Fragment>
  ) : (
    children
  );
};
