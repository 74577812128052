import * as React from "react";

function SvgGenericFileFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M54 24.56h-4.53V17a1 1 0 00-.93-1H10a1 1 0 00-1 1v30a1 1 0 001 1h38.32a.93.93 0 00.93-.72L55 25.78a1 1 0 00-1-1.22zm-8.58 0h-30a1 1 0 00-1 .79L13 30.93V20h32.46z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGenericFileFilled;
