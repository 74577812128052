import { FieldInputProps } from "formik";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";

import {
  DatePicker as AntDatePicker,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Select as AntSelect,
  Slider as AntSlider,
  SliderSingleProps,
  TimePicker as AntTimePicker
} from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import { InputProps, TextAreaProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import { SelectProps, SelectValue } from "antd/lib/select";
import { TimePickerProps } from "antd/lib/time-picker";

export const useAntFormikEventHandlers = (
  type: string,
  name: string,
  onChange: (e: React.ChangeEvent<any>) => void,
  onBlur: (e: React.FocusEvent<any>) => void
) => {
  const rewiredOnChange = useCallback(
    (value?: any) => {
      return onChange({
        target: { type, name, value }
      } as React.ChangeEvent<any>);
    },
    [name, onChange, type]
  );

  const rewiredOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.name = name;
      return onBlur(e);
    },
    [onBlur, name]
  );

  return { rewiredOnChange, rewiredOnBlur };
};

// this component requires no rewiring.
// BUT due to a production build issue, DO NOT simplify this as
// export const Input = AntInput;
export const Input: React.FC<InputProps> = (props) => <AntInput {...props} />;

// neither does this one
export const TextArea: React.FC<TextAreaProps> = (props) => (
  <AntInput.TextArea {...props} />
);

export const InputNumber: React.FC<
  Omit<InputNumberProps, "onChange"> & FieldInputProps<number>
> = ({ onChange, onBlur, name, ...props }) => {
  const { rewiredOnChange, rewiredOnBlur } = useAntFormikEventHandlers(
    "number",
    name,
    onChange,
    onBlur
  );

  return (
    <AntInputNumber
      {...props}
      onChange={rewiredOnChange}
      onBlur={rewiredOnBlur}
    />
  );
};

export const Select: React.FC<
  Omit<SelectProps<SelectValue>, "onChange"> & FieldInputProps<number | string>
> = ({ onChange, onBlur, name, ...props }) => {
  const { rewiredOnChange, rewiredOnBlur } = useAntFormikEventHandlers(
    "select",
    name,
    onChange,
    onBlur
  );

  return (
    <AntSelect
      {...props}
      onChange={(evt) => {
        rewiredOnChange(evt);
      }}
      onBlur={rewiredOnBlur}
    />
  );
};

export const DatePicker: React.FC<
  Omit<DatePickerProps, "onChange"> & FieldInputProps<Date>
> = ({ onChange, onBlur, name, value, ...props }) => {
  const { rewiredOnChange, rewiredOnBlur } = useAntFormikEventHandlers(
    "date",
    name,
    onChange,
    onBlur
  );

  const innerOnChange = useCallback(
    (value?: Moment | null) => {
      return rewiredOnChange(value?.toDate());
    },
    [rewiredOnChange]
  );

  return (
    <AntDatePicker
      {...props}
      picker={props.picker || "date"}
      value={value ? moment(value) : null}
      onChange={innerOnChange}
      onBlur={rewiredOnBlur}
    />
  );
};

export const TimePicker: React.FC<
  Omit<TimePickerProps, "onChange"> & FieldInputProps<number>
> = ({ onChange, onBlur, name, value, ...props }) => {
  const format = (props.format as string) || "HH:mm";

  const { rewiredOnChange, rewiredOnBlur } = useAntFormikEventHandlers(
    "string",
    name,
    onChange,
    onBlur
  );

  const innerOnChange = useCallback(
    (value?: Moment | null) => {
      return rewiredOnChange(value?.format(format) || "");
    },
    [format, rewiredOnChange]
  );

  return (
    <AntTimePicker
      {...props}
      value={moment(value, format)}
      onChange={innerOnChange}
      onBlur={rewiredOnBlur}
    />
  );
};

// Only handles single slider for now
export const Slider: React.FC<
  Omit<SliderSingleProps, "onChange"> & FieldInputProps<number>
> = ({ onChange, onBlur, name, value: outerValue, ...props }) => {
  const [innerValue, setInnerValue] = useState<number | undefined>();

  useEffect(() => {
    setInnerValue(outerValue);
  }, [outerValue]);

  const { rewiredOnChange, rewiredOnBlur } = useAntFormikEventHandlers(
    "string",
    name,
    onChange,
    onBlur
  );

  const innerOnChange = useCallback(() => {
    rewiredOnChange(innerValue);
    return rewiredOnBlur({
      target: {
        type: "string",
        name
      }
    } as React.FocusEvent<HTMLInputElement>);
  }, [innerValue, name, rewiredOnBlur, rewiredOnChange]);

  return (
    <AntSlider
      {...props}
      value={innerValue}
      onChange={setInnerValue}
      onAfterChange={innerOnChange}
    />
  );
};
