import * as React from "react";

function SvgCampaignImageFilled(props) {
  return (
    <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
      <path
        d="M21 40a1 1 0 01-1-1V25a1 1 0 011-1h22a1 1 0 011 1v14a1 1 0 01-1 1zm3.07-2H42v-6.14L39.07 29l-6 6-3-3zm1-6.57a2 2 0 002-2 2 2 0 00-2-2 2 2 0 00-2 2 2 2 0 002 2zM13.28 48h37.44A3.25 3.25 0 0054 44.86V19.14A3.25 3.25 0 0050.72 16H13.28A3.2 3.2 0 0010 19.14v25.72A3.2 3.2 0 0013.28 48z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCampaignImageFilled;
